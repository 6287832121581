<template>
  <div
    class="relative"
  >
    <button
      :class="buttonClasses"
      type="button"
      @click.stop.prevent="showTooltip = !showTooltip"
      @mouseover="isMobile ? null : showTooltip = true"
      @mouseleave="isMobile ? null : showTooltip = false"
    >
      <slot name="custom-content">
        <p>
          {{ mainLabel }}
        </p>
        <QuestionMarkCircleIcon class="shrink-0 w-4 h-4" />
      </slot>
    </button>
    <div
      v-if="showTooltip"
      :class="[arrowPositionClasses, arrowColorClasses]"
      class="absolute z-10 border border-x-8 border-t-0 border-b-8 border-x-transparent border-solid"
    />
    <div
      v-if="showTooltip"
      class="flex overflow-y-auto absolute z-10 flex-col p-2 mt-2 max-h-96 text-sm text-white rounded-md divide-gray-100 focus:outline-none origin-center"
      :class="[informationClasses, informationColorClasses, informationPositionClasses]"
    >
      <p class="flex flex-col">
        <span
          v-for="info, index in arrayOfIntormation"
          :key="index"
        >
          {{ info }}
        </span>
      </p>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, watch, inject } from 'vue';
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline';

interface LokalDropDownProps {
  information: string
  mainLabel?: string
  informationClasses?: string
  arrowColorClasses?: string
  informationColorClasses?: string
  buttonClasses?: string
  positionOptions?: 'center' | 'left' | 'right' | ''
  arrowClasses?: string
}

const props = withDefaults(defineProps<LokalDropDownProps>(), {
  mainLabel: '',
  informationClasses: 'w-52',
  buttonClasses: 'flex z-0 flex-col items-center space-x-1 focus:outline-none md:flex-row md:space-x-2',
  positionOptions: 'left',
  arrowClasses: '',
  arrowColorClasses: 'border-lk-light-purple',
  informationColorClasses: 'bg-lk-light-purple',
});

const isMobile = inject('isMobile');
const showTooltip = ref(false);
const arrayOfIntormation = ref(props.information.split('<br>'));

const informationPositionClasses = computed(() => {
  if (props.positionOptions === 'center') return 'left-1/2 -translate-x-1/2';
  if (props.positionOptions === 'left') return 'left-0';
  if (props.positionOptions === 'right') return 'right-0';

  return '';
});

const arrowPositionClasses = computed(() => {
  if (props.arrowClasses) return props.arrowClasses;
  if (props.positionOptions === 'center') return 'left-1/2 -translate-x-1/2';
  if (props.positionOptions === 'left') return 'left-4';
  if (props.positionOptions === 'right') return 'right-4';

  return '';
});

watch(() => props.information, () => {
  arrayOfIntormation.value = props.information.split('<br>');
});
</script>


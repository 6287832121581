import { ref, computed, watch, type ComputedRef } from 'vue';
import useCartStore from 'stores/cart';
import { useMutation } from 'vue-query';
import { max } from 'lodash';
import cartProductsApi from 'api/cart-products';
import useProductWholesalePriceMethods from './product-wholesale-price';

// NOTE:
// We use quantity and quantityField to manage the quantity of the product in the cart. This is because
// we modify the quantity in multiple components. This should be refactored to use quantity in a
// state manager like Pinia.

// eslint-disable-next-line max-statements
export default function useCartManager(product: ComputedRef<Product>, variant?: Variant) {
  const cartStore = useCartStore();
  const quantity = computed(() => cartStore.getProductItem(product.value.id)?.quantity || 0);
  const quantityField = ref(quantity.value);
  const inventoryQuantity = computed(() => product.value.inventory?.quantity || 0);
  const inventoryEnabled = computed(() => product.value.inventory?.enable || false);
  const { getProductWholesalePrice } = useProductWholesalePriceMethods(product.value, quantity);

  const increaseButtonDisabled = computed(() => {
    if (product.value?.inventory?.enable) {
      if (quantity.value === 0) {
        const baseQuantity = max([product.value?.packUnits, product.value?.minimumPurchaseQuantity]);

        return baseQuantity > product.value?.inventory?.quantity;
      }

      return quantity.value + product.value?.packUnits > product.value?.inventory?.quantity;
    }

    return false;
  });

  const cartProductMutation = useMutation((newQuantity: number) =>
    cartProductsApi.change(product.value.id, { quantity: newQuantity, productId: product.value.id }),
  );

  const cartProductMutationIsLoading = computed(() => cartProductMutation.isLoading.value);

  function increaseUnits(newQuantity: number) {
    cartStore.includeProductToCart(product.value, newQuantity, getProductWholesalePrice(newQuantity), variant);
    cartProductMutation.mutate(newQuantity);
  }
  function removeUnits(newQuantity: number) {
    cartStore.removeProductUnitFromCart(product.value, newQuantity, getProductWholesalePrice(newQuantity));
    cartProductMutation.mutate(newQuantity);
  }
  function increaseOneUnit() {
    if (quantity.value === 0) {
      const baseQuantity = max([product.value.packUnits, product.value.minimumPurchaseQuantity]);
      if (inventoryEnabled.value && inventoryQuantity.value < baseQuantity) return;
      increaseUnits(baseQuantity);
    } else {
      if (inventoryEnabled.value && quantity.value + product.value.packUnits > inventoryQuantity.value) return;
      increaseUnits(quantity.value + product.value.packUnits);
    }
    quantityField.value = quantity.value;
  }
  function removeOneUnit() {
    if (quantity.value === product.value.minimumPurchaseQuantity) {
      removeUnits(0);
    } else {
      removeUnits(quantity.value - product.value.packUnits);
    }
    quantityField.value = quantity.value;
  }

  function manageIncreaseOnQuantityChange() {
    if (inventoryEnabled.value && quantityField.value > inventoryQuantity.value) {
      quantityField.value = inventoryQuantity.value;
    }
    increaseUnits(quantityField.value);
  }

  function manageDecreaseOnQuantityChange() {
    if (quantityField.value < product.value.minimumPurchaseQuantity) {
      quantityField.value = 0;
    }
    removeUnits(quantityField.value);
  }

  function manageQuantityChange() {
    if (quantityField.value > cartStore.items[product.value.id].quantity) {
      manageIncreaseOnQuantityChange();
    } else if (quantityField.value < cartStore.items[product.value.id].quantity) {
      manageDecreaseOnQuantityChange();
    }
  }

  function removeProductFromCart() {
    cartStore.removeProductUnitFromCart(product.value, 0, getProductWholesalePrice(0));
    cartProductMutation.mutate(0);
  }

  // this is because the quantity field is used in different components, so when it changes in one
  // this triggers the update
  watch(quantity, () => {
    quantityField.value = quantity.value;
  });

  function clearCartStore() {
    removeUnits(quantity.value);
  }

  return {
    quantity, quantityField, increaseOneUnit, removeOneUnit, manageQuantityChange, clearCartStore,
    increaseButtonDisabled, cartProductMutationIsLoading, removeProductFromCart,
  };
}

<template>
  <a
    v-if="!isLateOnpaymentLoading && lateOnPayments"
    class=" p-2 w-full text-sm text-center text-white bg-red-500 sm:px-3 md:text-base"
    :href="`/shops/${shopId}/admin?tab=unpaidOrders`"
  >
    Tienes una o más ordenes pendientes de pago.
    <span class="underline">
      Ingresa acá
    </span>
    para revisar el detalle.
  </a>
  <a
    v-else-if="!isBalanceAccountLoading && balanceAccount.maximumCreditsApprovedDate &&
      balanceAccount.maximumCreditsCents > 0"
    class=" p-2 w-full text-sm font-light text-center text-white bg-lk-light-green/80 sm:px-3 md:text-base"
    :href="`https://wa.me/${contactNumber}?text=¡Hola! Me gustaría solicitar un aumento de cupo.`"
    target="_blank"
    noopener
  >
    <span v-if="balanceAccount.creditsLeft <= 0">
      No te queda crédito disponible.
    </span>
    <span v-else>
      Tienes un crédito disponible de
      {{ useFormatCurrency(balanceAccount?.creditsLeft) }}.
    </span>
    Solicita un aumento
    <span class="font-normal underline">
      aquí
    </span>
    .
  </a>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { useQuery } from 'vue-query';
import shopsApi from 'api/shops';
import useSessionStore from 'stores/user-store';
import useBalanceAccountQuery from './queries/balance-account-query';
import useFormatCurrency from './use/format-currency';

const sessionStore = useSessionStore();
const shopId = computed(() => sessionStore.user?.shopId);

const lateOnPaymentQuery = useQuery(
  ['lateOnPayment', shopId.value],
  () => shopsApi.lateOnPayments(shopId.value),
  { enabled: !!shopId.value },
);
const isLateOnpaymentLoading = computed(() => lateOnPaymentQuery.isLoading.value);
const lateOnPayments = computed(() => lateOnPaymentQuery.data.value?.data.lateOnPayments);

const balanceAccountQueryEnabled = computed(() => !isLateOnpaymentLoading.value && lateOnPayments.value);
const {
  balanceAccount, isLoading: isBalanceAccountLoading,
} = useBalanceAccountQuery(computed(() => sessionStore.user?.shopId), balanceAccountQueryEnabled);

const contactNumber = computed(() => process.env.CONTACT_NUMBER);
</script>

import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const PAGE_SIZE = 24;

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  index(makerId: number, pageNumber: number, queryParams?: string) {
    const path = `/api/internal/makers/${makerId}/enviame_pickups`;

    return api({
      method: 'get',
      url: `${path}?page[number]=${pageNumber}&page[size]=${PAGE_SIZE}&${queryParams}`,
      headers,
    });
  },
  create(data: Partial<EnviamePickup>) {
    return api({
      method: 'post',
      url: '/api/internal/enviame_pickups',
      headers,
      data,
    });
  },
};


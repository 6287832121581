<template>
  <div class="flex flex-col w-full">
    <div class="flex items-center w-full">
      <p
        class="shrink-0 p-2 pr-1 text-sm text-gray-400 rounded-md rounded-r-none border border-r-0"
        :class="{
          'border-lk-green': contactNumberSelected,
          'border-red-500': Object.keys(errors).length > 0 && !contactNumberSelected,
          'border-lk-light-gray': !contactNumberSelected
        }"
      >
        +56
      </p>
      <Field
        v-model="contactNumber"
        name="user[contact_number]"
        label="Número de contacto"
        type="string"
        placeholder="Número de contacto"
        class="p-2 pl-0 w-full text-sm rounded-md rounded-l-none border border-l-0 focus:border-l-0 border-lk-light-gray focus:border-lk-green focus:outline-none"
        :class="{ 'border-red-500': Object.keys(errors).length > 0 }"
        :rules="{ required: false, length: 9, numeric: true }"
        @focus="handleFocus"
        @blur="handleFocus"
        @change="updateContactNumber"
      />
    </div>
    <ErrorMessage
      name="user[contact_number]"
      class="text-sm text-red-500"
    />
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import { useForm, Field, ErrorMessage } from 'vee-validate';

const PHONE_NUMBER_LENGTH = 9;
const SLICE_START = 2;

const props = defineProps<{
  modelValue: string
}>();

const emit = defineEmits<{(e: 'update:modelValue', value: string): void, (e: 'noErrors'): void}>();

const { errors, validate } = useForm();
const contactNumberSelected = ref(false);

function parseContactNumber() {
  if (props.modelValue.length > PHONE_NUMBER_LENGTH && props.modelValue.startsWith('56')) {
    return props.modelValue.slice(SLICE_START);
  }

  return props.modelValue;
}
const contactNumber = ref(parseContactNumber());

function handleFocus() {
  contactNumberSelected.value = !contactNumberSelected.value;
}

async function updateContactNumber() {
  const { valid } = await validate();
  if (valid) emit('update:modelValue', contactNumber.value);
}
</script>

<template>
  <div class="flex justify-between items-center w-full sm:px-3">
    <button
      class="flex items-center mr-5 rounded-md border-transparent hover:md:border-lk-green md:p-2 md:border"
      @click="openAddressDialog"
    >
      <MapPinIcon class="shrink-0 mr-2 w-4 h-4 fill-lk-green md:w-6 md:h-6" />
      <p class="flex items-center space-x-2 text-sm md:text-base">
        <span>Enviar a:</span>
        <span v-if="currentAddress?.id">{{ currentAddress?.communeName }}</span>
        <span v-else-if="shop?.referenceCommuneId">{{ referenceCommune?.name }}</span>
      </p>
    </button>
    <address-new-dialog
      v-if="shop?.id && showNewAddressDialog"
      v-model="showNewAddressDialog"
      :addressable="shop"
      :addressable-id="shop.id"
      addressable-type="Shop"
      :initial-region-id="initialRegionId"
      :initial-commune-id="initialCommuneId"
      @address-created="updateShopDefaultAddressId"
    />
    <order-checkout-addresses-edit-dialog
      v-if="shop?.id && showEditAddressDialog"
      v-model="showEditAddressDialog"
      :addressable-id="shop.id"
      addressable-type="Shop"
      :addressable="shop"
      @address-selected="updateShopDefaultAddressId"
    />
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, watch, onMounted } from 'vue';
import { MapPinIcon } from '@heroicons/vue/24/solid';
import shopsApi from 'api/shops';
import { useMutation } from 'vue-query';
import useSessionStore from 'stores/user-store';
import useCommunesQuery from './queries/communes-query';
import OrderCheckoutAddressesEditDialog from './order-checkout-addresses-edit-dialog.vue';
import addressNewDialog from './address-new-dialog.vue';

const props = defineProps<{
  shop: Shop
  addresses: Address[]
}>();

const emit = defineEmits<{(e: 'update:shopAndAddresses'): void}>();

const sessionStore = useSessionStore();
const currentAddress = computed(() => {
  if (props.shop?.defaultAddressId && props.addresses?.length && props.addresses?.length > 0) {
    const foundAddress = props.addresses?.find((address: Address) => address.id === props.shop.defaultAddressId);
    if (!!foundAddress) return foundAddress;
  }

  return props.addresses?.[0];
});
const { communes } = useCommunesQuery();
const referenceCommune = computed(() =>
  communes.value?.find((commune) => commune.id === props.shop?.referenceCommuneId));
const showEditAddressDialog = ref(false);
const showNewAddressDialog = ref(false);
const initialRegionId = computed(() => {
  if (props.addresses?.length && props.addresses?.length > 0) return undefined;

  return props.shop?.referenceRegionId;
});
const initialCommuneId = computed(() => {
  if (props.addresses?.length && props.addresses?.length > 0) return undefined;

  return props.shop?.referenceCommuneId;
});

function openAddressDialog() {
  if (props.addresses?.length && props.addresses?.length > 0) {
    showEditAddressDialog.value = true;
  } else {
    showNewAddressDialog.value = true;
  }
}

const updateShopDefaultAddressIdMutation = useMutation(
  (addressId: number) => shopsApi.update(props.shop.id, { defaultAddressId: addressId }), {
    onSuccess: () => {
      emit('update:shopAndAddresses');
    },
  });

function updateShopDefaultAddressId(addressId: number) {
  updateShopDefaultAddressIdMutation.mutate(addressId);
}

watch(currentAddress, () => {
  if (currentAddress.value?.id) {
    sessionStore.updateCurrentAddress(currentAddress.value);
  }
});

onMounted(() => {
  if (currentAddress.value?.id) {
    sessionStore.updateCurrentAddress(currentAddress.value);
  }
});
</script>

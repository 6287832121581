<template>
  <div
    class="flex flex-col p-3 mb-2 w-full rounded-md border md:p-4"
  >
    <div class="flex items-center mb-1">
      <p class="space-x-1 text-sm md:text-base">
        <span>
          Retiro #{{ enviamePickup.id }}
        </span>
        <span class="">·</span>
        <span class="font-light">{{ timeToDate(enviamePickup.pickupDate) }}</span>
        <span class="">·</span>
        <span class="font-light">{{ enviamePickup.carrierName }}</span>
      </p>
      <div
        v-if="scheduled"
        class="py-0.5 px-1 ml-auto text-sm text-lk-green rounded-md border border-lk-green md:text-base"
      >
        Agendado
      </div>
    </div>
    <p class="font-light">
      <span>{{ enviamePickup.address }}</span>
    </p>
    <p
      v-if="enviamePickup.information"
      class="font-light"
    >
      <span> {{ enviamePickup.information }}</span>
    </p>
    <p class="font-light">
      Fecha de creación: {{ timeToDate(enviamePickup.createdAt) }}
    </p>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import useDateTools from './use/date-tools';

const props = defineProps<{
  enviamePickup: EnviamePickup;
}>();

const { timeToDate } = useDateTools();

const scheduled = computed(() => !!props.enviamePickup.enviameId);
</script>

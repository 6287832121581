<template>
  <form class="flex flex-col-reverse w-full text-gray-700 bg-white divide-x lg:flex-row">
    <div class="flex p-3 w-full h-full md:p-5 lg:w-1/2 xl:p-10">
      <div class="flex flex-col w-full lg:ml-auto lg:max-w-xl">
        <p class="flex items-center mb-3 text-sm md:mb-5">
          <a
            href="/orders/new"
            class="font-light hover:underline"
          >Carrito</a>
          <span class="mx-1">></span>
          <span class="">Checkout</span>
        </p>
        <div class="mb-5">
          <shop-show
            class="rounded-md border"
            :show-addresses="false"
            :shop-id="shopId"
            title-container-classes="p-3 bg-gray-50 rounded-t-md md:p-5"
            content-container-classes="p-3 md:p-5"
            :summary="true"
          >
            <template #title="{ editShop }">
              <p class="text-base md:text-lg">
                {{ editShop ? 'Completa tu información' : 'Información' }} de faturación
              </p>
            </template>
          </shop-show>
          <Field
            v-model="shopId"
            hidden
            type="text"
            name="shopId"
            label="Tienda"
            :rules="{ required: true }"
          />
          <ErrorMessage
            name="shopId"
            class="mt-1 text-sm text-red-500"
            as="p"
          >
            Debes registrar tu tienda para continuar.
          </ErrorMessage>
          <template v-if="!!shop?.id">
            <Field
              v-model="businessRut"
              hidden
              type="text"
              name="businessRut"
              :rules="{ required: true }"
            />
            <ErrorMessage
              name="businessRut"
              class="mt-1 text-sm text-red-500"
              as="p"
            >
              Debes completar la información de tu tienda para continuar.
            </ErrorMessage>
          </template>
        </div>
        <p
          v-if="personalConsupmtion"
          class="p-3 mb-5 text-sm text-red-500 rounded-md border md:p-5 md:text-base"
        >
          No podemos continuar con tu orden. En Lokal vendemos productos a tiendas o empresas exclusivamente.
          Si deseas productos para consumo personal debes comprar directamente en la página de
          las marcas que te interesen.
        </p>
        <div
          v-else-if="!!shopId && !!businessRut"
          class="mb-5"
        >
          <order-checkout-addresses
            v-model="selectedAddress"
            v-model:selected-address-confirmed="selectedAddressConfirmed"
            :shop-id="shopId"
          />
          <Field
            v-model="selectedAddressId"
            hidden
            type="text"
            name="selectedAddressId"
            label="Dirección"
            :rules="{ required: true }"
          />
          <ErrorMessage
            name="selectedAddressId"
            class="mt-1 text-sm text-red-500"
            as="p"
          >
            Debes registrar una dirección para poder realizar el despacho.
          </ErrorMessage>
        </div>
        <order-checkout-billing-address
          v-if="selectedAddress?.id && shopId && selectedAddressConfirmed"
          v-model:billing-address="billingAddress"
          :shop-id="shopId"
          class="mb-5"
          :shipping-address="selectedAddress"
        />
        <form-input
          v-model="selectedBillingAddressId"
          hidden
          name="selectedBillingAddressId"
          :rules="{ required: true }"
        />
        <order-checkout-payment-options
          v-if="selectedAddress?.id && shop?.id && selectedAddressConfirmed && billingAddress?.id"
          v-model="paymentMethodName"
          :shop-id="shop.id"
          :selected-address="selectedAddress"
        />
        <Field
          v-model="paymentMethodName"
          name="paymentMethodName"
          :rules="{ required: true }"
          hidden
        />
        <template v-if="isMobile">
          <p
            v-if="!personalConsupmtion"
            class="flex flex-col justify-between px-2 mb-5 text-sm md:flex-row md:justify-start md:text-base"
          >
            <span>
              {{ purchaseNote }}
            </span>
          </p>
          <template
            v-if="!!shopId && !!businessRut && !!selectedAddressId && !cartStore.loadingNewShippingCost &&
              !!paymentMethodName"
          >
            <lokal-loading
              v-if="isLoading"
              :loading="isLoading"
              class="self-center"
            />
            <button
              v-else
              class="p-2 mb-1 text-sm text-white bg-lk-green rounded-md"
              :class="{ 'hover:bg-lk-green-deep': Object.keys(errors).length === 0,
                        'opacity-50': Object.keys(errors).length > 0 }"
              :disabled="Object.keys(errors).length > 0"
              type="button"
              @click="validateForm"
            >
              Generar orden de compra
            </button>
          </template>
          <a
            href="/orders/new"
            class="self-center mb-3 text-sm hover:underline"
          >
            Volver al carrito
          </a>
          <div
            v-if="Object.keys(errors).length > 0"
            class="flex flex-col items-center"
          >
            <template
              v-for="(_, index) in errors"
              :key="index"
            >
              <ErrorMessage
                :name="index"
                class="text-sm text-red-500"
              />
            </template>
          </div>
        </template>
      </div>
    </div>
    <button
      v-if="isMobile"
      class="flex justify-between items-center py-3 px-5 w-full bg-gray-50 md:py-5"
      :class="{ 'bg-gray-100': showOrderResume }"
      type="button"
      @click="showOrderResume = !showOrderResume"
    >
      <div class="flex items-center space-x-2">
        <p class="flex items-center">
          <ShoppingCartIcon class="shrink-0 mr-2 w-4 h-4" />
          <span>{{ showOrderResume ? 'Esconder' : 'Resume de Orden' }}</span>
        </p>
        <ChevronDownIcon
          class="w-4 h-4 md:w-5 md:h-5"
          :class="{ 'rotate-180': showOrderResume }"
        />
      </div>
      <div v-if="!showOrderResume">
        <lokal-loading
          v-if="!selectedAddress?.id || shippingCostIsLoading || cartStore.loadingVolumeDiscount"
          size="xs"
          :loading="!selectedAddress?.id || shippingCostIsLoading || cartStore.loadingVolumeDiscount"
        />
        <p v-else>
          {{ useFormatCurrency(totalAmountWithVat) }}
        </p>
      </div>
    </button>
    <div
      v-if="(isMobile && showOrderResume) || !isMobile"
      class="flex p-3 w-full h-full bg-gray-50 md:p-5 lg:w-1/2 xl:p-10"
    >
      <div class="flex relative flex-col w-full lg:max-w-xl">
        <div class="flex sticky top-2 flex-col w-full bg-gray-50 md:self-center md:max-w-xl lg:self-auto">
          <order-checkout-resume
            :selected-address="selectedAddress"
          />
          <template v-if="!isMobile">
            <p
              v-if="!personalConsupmtion"
              class="flex flex-col justify-between mb-5 text-sm md:flex-row md:justify-start md:text-base"
            >
              <span>
                {{ purchaseNote }}
              </span>
            </p>
            <template
              v-if="!!shopId && !!businessRut && !!selectedAddressId && !cartStore.loadingNewShippingCost &&
                !!paymentMethodName"
            >
              <lokal-loading
                v-if="isLoading"
                :loading="isLoading"
                class="self-center"
              />
              <button
                v-else
                class="p-2 mb-1 text-white bg-lk-green rounded-md"
                :class="{ 'hover:bg-lk-green-deep': Object.keys(errors).length === 0,
                          'opacity-50': Object.keys(errors).length > 0 }"
                :disabled="Object.keys(errors).length > 0"
                type="button"
                @click="validateForm"
              >
                Generar orden de compra
              </button>
            </template>
            <a
              href="/orders/new"
              class="self-center mb-3 text-sm hover:underline"
            >
              Volver al carrito
            </a>
            <div
              v-if="Object.keys(errors).length > 0"
              class="flex flex-col items-center"
            >
              <template
                v-for="(_, index) in errors"
                :key="index"
              >
                <ErrorMessage
                  :name="index"
                  class="text-sm text-red-500"
                />
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div hidden>
      <maker-shipping-price
        v-for="(makerId, index) in cartStore.itemsMakerIds"
        :key="index"
        :maker-id="makerId"
        :destination-address="selectedAddress"
      />
      <order-checkout-resume-maker-volume-discount
        v-for="makerId in cartStore.itemsMakerIds"
        :key="`order-checkout-resume-maker-volume-discount-${makerId}-hidden`"
        :maker-id="makerId"
      />
      <form-input
        v-for="makerId in keys(cartStore.discountCodes)"
        :key="`discount-code-${makerId}`"
        v-model="cartStore.discountCodes[parseInt(makerId, 10)].code"
        :rules="{ required: false, isValidDiscountCode: makerId }"
        :name="`discount-code-${makerId}`"
        class="w-full"
      />
      <template
        v-for="item in cartStore.items"
        :key="`item-stock-validation-${item.product.id}`"
      >
        <Field
          v-model="item.quantity"
          :label="item.product.name"
          :name="`item-stock-validation-${item.product.id}`"
          :rules="{ asyncStockValidation: item.product.id }"
        />
      </template>
    </div>
  </form>
</template>
<script setup lang="ts">
import { ref, inject, computed, type ComputedRef } from 'vue';
import { values, keys, snakeCase, round } from 'lodash';
import { useForm, Field, ErrorMessage } from 'vee-validate';
import { ChevronDownIcon, ShoppingCartIcon } from '@heroicons/vue/24/outline';
import useSessionStore from 'stores/user-store';
import useCartStore from 'stores/cart';
import { useMutation } from 'vue-query';
import ordersApi from 'api/orders';
import subOrdersApi from 'api/sub-orders';
import cartsApi from 'api/carts';
import { billToolsKey, type BillTools } from '../symbols';
import ShopShow from './shop-show.vue';
import useFormatCurrency from './use/format-currency';
import useTotalItemPriceMethods from './use/total-item-price-methods';
import OrderCheckoutAddresses from './order-checkout-addresses.vue';
import MakerShippingPrice from './shared/maker-shipping-price.vue';
import useShopQuery from './queries/shop-query';
import OrderCheckoutPaymentOptions from './order-checkout-payment-options.vue';
import OrderCheckoutResume from './order-checkout-resume.vue';
import OrderCheckoutBillingAddress from './order-checkout-billing-address.vue';
import useBalanceAccountQuery from './queries/balance-account-query';
import FormInput from './shared/form-input.vue';
import orderCheckoutResumeMakerVolumeDiscount from './order-checkout-resume-maker-volume-discount.vue';

const isMobile = inject('isMobile');
const sessionStore = useSessionStore();
const cartStore = useCartStore();
const shopId = computed(() => sessionStore.user.shopId || undefined);
const { totalProductPriceForItems } = useTotalItemPriceMethods();
const selectedAddress = ref({} as Address);
const selectedAddressId = computed(() => selectedAddress.value?.id);
const billingAddress = ref({} as Address);
const selectedBillingAddressId = computed(() => billingAddress.value?.id);
const selectedAddressConfirmed = ref(false);
const shippingCost = computed(() => cartStore.shippingCost || 0);
const shippingCostIsLoading = computed(() => cartStore.loadingNewShippingCost);
const totalProductsPrice = computed(() =>
  totalProductPriceForItems(values(cartStore.items)),
);

const billTools = inject(billToolsKey) as BillTools;

const { shop } = useShopQuery(shopId as ComputedRef<number>);
const businessRut = computed(() => shop.value?.businessRut);
const personalConsupmtion = computed(
  () => shop.value?.kind === 'personal_consumption',
);

const paymentMethodName = ref('');
const showOrderResume = ref(false);

const order = ref({} as Order);

function subOrderProductPartials(makerName: string) {
  return cartStore.groupedItems[makerName].map((item) => (
    { productId: item.product.id, variantId: item.variant?.id, quantity: item.quantity }
  ));
}

function getShippingData(makerId: number) {
  return {
    shippingCostAmountCents: cartStore.shippingCostPerMaker[makerId],
    originalShippingCostAmountCents: cartStore.originalShippingCostPerMaker[makerId],
    lokalShippingCostAmountCents: cartStore.lokalShippingCostPerMaker[makerId],
    makerShippingCostAmountCents: cartStore.makerShippingCostPerMaker[makerId],
  };
}

function createSubOrderMutation(makerName: string) {
  const makerId = cartStore.groupedItems[makerName][0].product.makerId;
  const shippingData = getShippingData(makerId);
  const discountCodeId = cartStore.discountCodes[makerId]?.id;
  const partialData = { orderId: order.value.id, makerId, discountCodeId,
    subOrderProductPartials: subOrderProductPartials(makerName), ...shippingData };
  if (!!cartStore.shopPickupAddressIds[makerId]) {
    const shopPickupAddressId = cartStore.shopPickupAddressIds[makerId];
    const data = { shopPickupAddressId, shipmentMethod: 'shop_pickup', ...partialData };

    return subOrdersApi.create(data);
  }

  return subOrdersApi.create(partialData);
}

const totalAmountWithoutVat = computed(() => totalProductsPrice.value + shippingCost.value -
  cartStore.totalVolumeDiscounts - cartStore.totalDiscountCodeAmounts);
const totalAmountWithVat = computed(() =>
  round((totalAmountWithoutVat.value) * billTools.ivaValue),
);

const destroyCartMutation = useMutation(() => cartsApi.destroy(), {
  onSuccess: () => {
    cartStore.reset();
    window.location.href = `/orders/${order.value.id}?new_order=true`;
  },
});

function deleteDiscountCodes() {
  keys(cartStore.discountCodes).forEach((makerId) => {
    delete cartStore.discountCodes[parseInt(makerId, 10)];
    delete cartStore.discountCodeAmounts[parseInt(makerId, 10)];
  });
}

function deleteShopPickupAddressIds() {
  keys(cartStore.shopPickupAddressIds).forEach((makerId) => {
    delete cartStore.shopPickupAddressIds[parseInt(makerId, 10)];
  });
}

const orderMutation = useMutation(() => {
  const data = { shopId: shopId.value as number, addressId: selectedAddress.value.id,
    billingAddressId: billingAddress.value.id, shippingCostAmountCents: shippingCost.value,
    paymentMethod: snakeCase(paymentMethodName.value),
  };

  return ordersApi.create(data);
}, {
  onSuccess: async (response) => {
    order.value = response.data?.order as Order;
    const subOrderPromises = keys(cartStore.groupedItems).map((makerName) => createSubOrderMutation(makerName));
    await Promise.all(subOrderPromises);
    deleteDiscountCodes();
    deleteShopPickupAddressIds();
    destroyCartMutation.mutate();
  },
});

const isLoading = computed(() => orderMutation.isLoading.value);

const { errors, validate } = useForm();
async function validateForm() {
  const { valid } = await validate();
  if (valid) orderMutation.mutate();
}

const { balanceAccount } = useBalanceAccountQuery(shopId);
const creditsLeft = computed(() => balanceAccount.value?.creditsLeft);
const generalMessage = ref(`
    NOTA:
    Todas las tiendas físicas tienen un crédito inicial máximo de $200.000 sujeto a aprobación interna
    del equipo de crédito.
    Para compras superiores al monto aprobado, deberás abonar la diferencia.
    Si quieres acceder a más crédito contáctanos.
`);
const maximumCreditsApprovedDateNotPresent = computed(() => !balanceAccount.value?.maximumCreditsApprovedDate);
const purchaseNote = computed(() => {
  if (maximumCreditsApprovedDateNotPresent.value) return generalMessage.value;
  if (creditsLeft.value) {
    if (creditsLeft.value > 0) {
      return `
        NOTA:
        Tienes un crédito disponible de ${useFormatCurrency(creditsLeft.value)} de un total de
        ${useFormatCurrency(balanceAccount.value.maximumCreditsCents)} aprobados.
        Si te gustaría acceder a más crédito contáctanos.
      `;
    } else if (creditsLeft.value < 0) {
      return `
      NOTA:
      Ya no tienes créditos disponibles. Has utilizado ${useFormatCurrency(balanceAccount.value.maximumCreditsCents)}
      y además tienes un saldo pendiente de ${useFormatCurrency(-creditsLeft.value)}.
      Si te gustaría acceder a más crédito contáctanos.
    `;
    } else if (creditsLeft.value === 0) {
      return `
      NOTA:
      Has utilizado todo tu crédito disponible de ${useFormatCurrency(balanceAccount.value.maximumCreditsCents)}.
      Si quieres acceder a más crédito contáctanos.
    `;
    }
  }

  return generalMessage.value;
});
</script>

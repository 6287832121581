<template>
  <div
    ref="stickyElement"
    class="flex sticky -top-1 z-10 flex-col w-full max-w-3xl bg-gray-50"
    :class="{ 'pb-5 md:pt-5': isStickyElement, 'py-5': !isStickyElement }"
  >
    <div class="flex flex-col p-5 space-y-2 text-xs bg-white rounded-md md:flex-row md:items-center md:px-10 md:space-y-0 md:text-sm">
      <div
        v-if="isMobileOrTablet"
        class="flex items-center w-full"
      >
        <div
          class="shrink-0 p-2 text-white bg-lk-green rounded-full md:p-2"
        >
          Pendiente
        </div>
        <div
          class="w-full border border-b"
          :class="{ 'border-gray-300': currentSubOrder.status === 'pending',
                    'border-lk-green': currentSubOrder.status !== 'pending' }"
        />
        <div
          class="shrink-0 p-2 text-white rounded-full md:p-2"
          :class="{ 'bg-gray-300': currentSubOrder.status === 'pending',
                    'bg-lk-green': currentSubOrder.status !== 'pending' }"
        >
          Confirmado
        </div>
      </div>
      <div
        v-if="isMobileOrTablet"
        class="flex items-center w-full"
      >
        <div
          class="shrink-0 p-2 text-white rounded-full md:p-2"
          :class="{ 'bg-gray-300': !shippingDetailsUploadedAndNotPendingStatus,
                    'bg-lk-green': shippingDetailsUploadedAndNotPendingStatus }"
        >
          Detalles de envío
        </div>
        <div
          class="w-full border border-b"
          :class="{ 'border-gray-300': ['pending', 'confirmed'].includes(currentSubOrder.status),
                    'border-lk-green': !['pending', 'confirmed'].includes(currentSubOrder.status) }"
        />
        <div
          class="shrink-0 p-2 text-white rounded-full md:p-2"
          :class="{ 'bg-gray-300': ['pending', 'confirmed'].includes(currentSubOrder.status),
                    'bg-lk-green': !['pending', 'confirmed'].includes(currentSubOrder.status) }"
        >
          En despacho
        </div>
        <div
          class="w-full border border-b"
          :class="{
            'border-gray-300': ['pending', 'confirmed', 'delivery_in_progress'].includes(currentSubOrder.status),
            'border-lk-green': !['pending', 'confirmed', 'delivery_in_progress'].includes(currentSubOrder.status) }"
        />
        <div
          class="shrink-0 p-2 text-white rounded-full md:p-2"
          :class="{
            'bg-gray-300': ['pending', 'confirmed', 'delivery_in_progress'].includes(currentSubOrder.status),
            'bg-lk-green': !['pending', 'confirmed', 'delivery_in_progress'].includes(currentSubOrder.status) }"
        >
          Entregado
        </div>
      </div>
      <template v-else>
        <div
          class="shrink-0 p-2 text-white bg-lk-green rounded-full md:p-2"
        >
          Pendiente
        </div>
        <div
          class="w-full border border-b"
          :class="{ 'border-gray-300': currentSubOrder.status === 'pending',
                    'border-lk-green': currentSubOrder.status !== 'pending' }"
        />
        <div
          class="shrink-0 p-2 text-white rounded-full md:p-2"
          :class="{ 'bg-gray-300': currentSubOrder.status === 'pending',
                    'bg-lk-green': currentSubOrder.status !== 'pending' }"
        >
          Confirmado
        </div>
        <div
          class="w-8 border border-b md:w-full"
          :class="{ 'border-gray-300': !shippingDetailsUploadedAndNotPendingStatus,
                    'border-lk-green': shippingDetailsUploadedAndNotPendingStatus }"
        />

        <div
          class="shrink-0 p-2 text-white rounded-full md:p-2"
          :class="{ 'bg-gray-300': !shippingDetailsUploadedAndNotPendingStatus,
                    'bg-lk-green': shippingDetailsUploadedAndNotPendingStatus }"
        >
          Detalles de envío
        </div>
        <div
          class="w-full border border-b"
          :class="{ 'border-gray-300': ['pending', 'confirmed'].includes(currentSubOrder.status),
                    'border-lk-green': !['pending', 'confirmed'].includes(currentSubOrder.status) }"
        />
        <div
          class="shrink-0 p-2 text-white rounded-full md:p-2"
          :class="{ 'bg-gray-300': ['pending', 'confirmed'].includes(currentSubOrder.status),
                    'bg-lk-green': !['pending', 'confirmed'].includes(currentSubOrder.status) }"
        >
          En despacho
        </div>
        <div
          class="w-full border border-b"
          :class="{
            'border-gray-300': ['pending', 'confirmed', 'delivery_in_progress'].includes(currentSubOrder.status),
            'border-lk-green': !['pending', 'confirmed', 'delivery_in_progress'].includes(currentSubOrder.status) }"
        />
        <div
          class="shrink-0 p-2 text-white rounded-full md:p-2"
          :class="{
            'bg-gray-300': ['pending', 'confirmed', 'delivery_in_progress'].includes(currentSubOrder.status),
            'bg-lk-green': !['pending', 'confirmed', 'delivery_in_progress'].includes(currentSubOrder.status) }"
        >
          Entregado
        </div>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { inject, ref, computed } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';

const props = defineProps<{
  subOrder: SubOrder,
}>();

const currentSubOrder = computed(() => props.subOrder);

const stickyElement = ref(null);
const isStickyElement = ref(false);
useIntersectionObserver(
  stickyElement,
  ([entry]) => {
    isStickyElement.value = entry.intersectionRatio < 1;
  },
  { threshold: 1 },
);

const isMobileOrTablet = inject('isMobileOrTablet');

const shippingDetailsUploaded = computed(() => (
  !!(currentSubOrder.value?.shipmentMethod &&
  (currentSubOrder.value?.shipmentMethod === 'maker' &&
  currentSubOrder.value?.makerShipmentDetailId) ||
  (currentSubOrder.value?.shipmentMethod === 'lokal' &&
  currentSubOrder.value?.shipmentDetailId))
));
const shippingDetailsUploadedAndNotPendingStatus = computed(() => (
  shippingDetailsUploaded.value && currentSubOrder.value?.status !== 'pending'
));
</script>


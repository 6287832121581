import { defineStore } from 'pinia';
import { ref, computed, watch } from 'vue';
import useUrlMethods from 'components/use/url-methods';

// eslint-disable-next-line max-statements
const useProductFilterStore = defineStore('productFilterStore', () => {
  const currentPage = ref(1);
  const searchText = ref('');
  const selectedMakers = ref([] as Maker[]);
  const selectedMakerIds = computed(() => selectedMakers.value.map((maker) => maker.slug as string));
  const selectedCategories = ref([] as Category[]);
  const selectedCategoryIds = computed(() => selectedCategories.value.map((category) => category.id));
  const selectedLabels = ref([] as Label[]);
  const selectedLabelIds = computed(() => selectedLabels.value.map((label) => label.id));
  const selectedCollections = ref([] as Collection[]);
  const selectedCollectionIds = computed(() => selectedCollections.value.map((collection) => collection.id));
  const onylRecommendedProducts = ref(false);
  const discountProducts = ref(false);
  const showOnlyProductsWithFreeShippingToCurrentAddress = ref(false);

  const { useUpdateUrlHistory } = useUrlMethods();
  function updateQueryParams() {
    useUpdateUrlHistory([
      { key: 'maker_ids', value: selectedMakerIds.value },
      { key: 'category_ids', value: selectedCategoryIds.value },
      { key: 'label_ids', value: selectedLabelIds.value },
      { key: 'collection_ids', value: selectedCollectionIds.value },
      { key: 'with_free_shippings', value: showOnlyProductsWithFreeShippingToCurrentAddress.value ? [true] : [] },
      { key: 'page', value: [currentPage.value] },
    ]);
  }

  watch(() => selectedMakers.value.length, updateQueryParams);
  watch(() => selectedCategories.value.length, updateQueryParams);
  watch(() => selectedLabels.value.length, updateQueryParams);
  watch(() => selectedCollections.value.length, updateQueryParams);
  watch(showOnlyProductsWithFreeShippingToCurrentAddress, updateQueryParams);
  watch(currentPage, updateQueryParams);

  return {
    searchText, selectedMakerIds, selectedCategoryIds, selectedMakers, selectedCategories, currentPage,
    onylRecommendedProducts, showOnlyProductsWithFreeShippingToCurrentAddress, selectedLabels, selectedLabelIds,
    selectedCollections, selectedCollectionIds, discountProducts,
  };
});

export default useProductFilterStore;

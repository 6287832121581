<template>
  <div class="flex p-3 border border-b-0">
    <div class="grid grid-cols-1 gap-y-1 w-full md:grid-cols-2 md:gap-2">
      <div class="flex flex-col mb-1 text-sm md:col-span-2 md:text-base">
        <p>Buscar por nombre de tienda:</p>
        <Field
          v-model="searchText"
          name="searchText"
          class="p-2 w-full rounded-md border border-lk-light-gray focus:outline-none"
        />
      </div>
      <div class="w-full">
        <p class="text-sm md:text-base">
          No han comprado en los últimos:
        </p>
        <lokal-single-selector
          v-model="inactiveTimePeriod"
          key-option="value"
          class="w-full"
          :options="timePeriodOptions"
          option-label="label"
          options-label="Selecciona un periodo"
          custom-label-color="bg-lk-light-green"
          size="md"
        />
      </div>
      <div class="w-full">
        <p class="text-sm md:text-base">
          Tipo de tienda:
        </p>
        <lokal-single-selector
          v-model="kindRecord"
          key-option="value"
          class="w-full"
          :options="kindOptions"
          option-label="label"
          options-label="Selecciona un tipo de tienda"
          custom-label-color="bg-lk-light-green"
          size="md"
        />
      </div>
      <div class="w-full">
        <p class="text-sm md:text-base">
          Regiones:
          <span
            v-for="region in selectedRegions"
            :key="region.id"
          >
            {{ region.name }},
          </span>
        </p>
        <lokal-generic-combobox
          v-model="selectedRegions"
          :multiple="true"
          class="w-full"
          :options="regions"
          :get-option-label="(option: Region) => option.name"
          :get-option-key="(option: Region) => option.id"
          options-label="Selecciona una región"
        />
      </div>
      <div class="self-end">
        <button
          v-if="kindRecord.value !== undefined || inactiveTimePeriod.value !== undefined || selectedRegions?.length"
          class="p-1.5 w-1/2 text-sm text-lk-green hover:text-white hover:bg-lk-green rounded-md border border-lk-green md:p-2 md:w-auto md:text-base"
          @click="kindRecord = {}; inactiveTimePeriod = {}; selectedRegions = []"
        >
          Limpiar búsqueda
        </button>
      </div>
    </div>
  </div>
  <div
    v-if="isLoading"
    class="p-3 rounded-b-md border"
  >
    <lokal-loading :loading="isLoading" />
  </div>
  <div
    v-if="shops?.length && !isLoading"
    class="flex flex-col"
  >
    <div class="flex mb-1 w-full text-xs border-x md:text-sm">
      <p class="mr-2 ml-auto">
        Total {{ totalResources }}
      </p>
    </div>
    <div class="flex flex-col rounded-md rounded-t-none border divide-y">
      <maker-shop-list-card
        v-for="shop in shops"
        :key="`shop-${shop.id}`"
        :maker="maker"
        :shop="shop"
        :class="{ 'hover:rounded-b-md': shop.id === shops[shops.length - 1]?.id }"
        @open-follow-up-dialog="openFollowUpDialog(shop)"
      />
    </div>
    <pagination-component
      v-if="!isLoading && shops?.length"
      :total-pages="totalPages"
      :current-page="currentPage"
      class="self-center"
      @set-new-page="(newPage: number) => currentPage = newPage"
    />
    <maker-new-follow-up-dialog
      v-model="showNewFollowUpDialog"
      :maker="maker"
      :follow-up-id="followUpId"
      follow-up-type="Shop"
      :followable-name="followableName"
    />
  </div>
  <div
    v-else
    class="flex flex-col border"
  >
    <p
      v-if="anyFilterSelected"
      class="p-5"
    >
      No hemos encontrado tiendas con los filtros seleccionados.
    </p>
    <p
      v-else
      class="p-5"
    >
      Aún no tienes clientes. No te preocupes, comparte tu link de referido con tus clientes actuales y
      empieza a recibir ordenes.
    </p>
  </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, ref, watch, onUnmounted } from 'vue';
import { useQuery } from 'vue-query';
import shopsApi from 'api/shops';
import { Field } from 'vee-validate';
import useUrlParamStore from 'stores/url-param-store';
import MakerShopListCard from './maker-shop-list-card.vue';
import PaginationComponent from './shared/pagination-component.vue';
import useCustomParseInt from './use/custom-parse-int';
import LokalSingleSelector, { type OptionRecord } from './shared/lokal-single-selector.vue';
import LokalGenericCombobox from './shared/lokal-generic-combobox.vue';
import useUrlMethods from './use/url-methods';
import makerNewFollowUpDialog from './maker-new-follow-up-dialog.vue';
import useRegionsQuery from './queries/regions-query';

const OPTIONS = [
  { value: 7, label: '7 días' },
  { value: 15, label: '15 días' },
  { value: 30, label: '30 días' },
  { value: 60, label: '60 días' },
  { value: 90, label: '90 días' },
];

const KINDOPTIONS = [
  { value: 4, label: 'Tienda física y online' },
  { value: 0, label: 'Tiendas físicas' },
  { value: 1, label: 'Tiendas online' },
  { value: 3, label: 'Otros' },
];

interface MakerClientListProps {
  maker: Maker
}

const props = defineProps<MakerClientListProps>();
const searchText = ref('');

const showNewFollowUpDialog = ref(false);
const followUpId = ref(0);
const followableName = ref('');
function openFollowUpDialog(shop: Shop) {
  followUpId.value = shop.id;
  followableName.value = shop.name;
  showNewFollowUpDialog.value = true;
}

const inactiveTimePeriod = ref({} as OptionRecord);
const timePeriodOptions = ref(OPTIONS as OptionRecord[]);
const kindRecord = ref({} as OptionRecord);
const kindOptions = ref(KINDOPTIONS as OptionRecord[]);

const { regions } = useRegionsQuery();
const selectedRegions = ref([] as Region[]);

const currentPage = ref(1);
const shopQueryOptions = computed(() => ({
  makerId: props.maker.id,
  pageNumber: currentPage.value,
  kind: kindRecord.value.value as number,
  shopName: searchText.value,
  regionNames: selectedRegions.value.map((region) => region.name),
}));
const shopQueryPrams = computed(() => {
  let queryParams = `page[number]=${shopQueryOptions.value.pageNumber}&page[size]=24`;
  if (shopQueryOptions.value.kind !== undefined) {
    queryParams = `${queryParams}&q[kind_eq]=${shopQueryOptions.value.kind}`;
  }
  if (shopQueryOptions.value.shopName) queryParams = `${queryParams}&q[name_cont]=${shopQueryOptions.value.shopName}`;
  if (shopQueryOptions.value.regionNames?.length) {
    shopQueryOptions.value.regionNames.forEach((regionName) => {
      queryParams = `${queryParams}&q[addresses_region_name_cont_any][]=${regionName}`;
    });
  }

  return queryParams;
});
const inactiveShopsQueryParams = computed(() => {
  const baseParams = shopQueryPrams.value;
  if (inactiveTimePeriod.value.value === undefined) return baseParams;

  return `${baseParams}&days_ago=${inactiveTimePeriod.value.value as number}`;
});
const shopsQuery = useQuery(
  ['shops', shopQueryPrams, inactiveShopsQueryParams],
  () => {
    if (inactiveTimePeriod.value.value === undefined) {
      return shopsApi.index(props.maker.id, shopQueryPrams.value);
    }

    return shopsApi.inactive(props.maker.id, inactiveShopsQueryParams.value);
  },
  { keepPreviousData: true },
);
const isLoading = computed(() => shopsQuery.isLoading.value || shopsQuery.isPreviousData.value);
const shops = computed(() => shopsQuery.data.value?.data.shops as Shop[]);
const totalResources = computed(() => useCustomParseInt(shopsQuery.data.value?.headers['x-total']));
const resourcesPerPage = computed(() => useCustomParseInt(shopsQuery.data.value?.headers['x-per-page']));
const totalPages = computed(() => Math.ceil(totalResources.value / resourcesPerPage.value));

const { updateUrlParams, getQueryParam } = useUrlMethods();
function updateQueryParams() {
  const inactiveTimePeriodValue = inactiveTimePeriod.value.value ? `${inactiveTimePeriod.value.value}` : '';
  updateUrlParams({ inactivePeriod: inactiveTimePeriodValue });
}

watch(inactiveTimePeriod, updateQueryParams);

function setInactiveTimePeriodFromQueryParam() {
  const inactiveTimePeriodParamValue = getQueryParam('inactivePeriod');
  if (inactiveTimePeriodParamValue) {
    const searchOption = OPTIONS.find((option) => option.value === parseInt(inactiveTimePeriodParamValue, 10));
    if (searchOption) inactiveTimePeriod.value = searchOption;
  }
}

const urlParamStore = useUrlParamStore();
onMounted(() => setInactiveTimePeriodFromQueryParam());
onUnmounted(() => {
  urlParamStore.paramsToUpdate.inactivePeriod = '';
});

const anyFilterSelected = computed(() =>
  !!searchText.value || !!inactiveTimePeriod.value.value || !!kindRecord.value.value ||
  selectedRegions.value?.length);
</script>

import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  index() {
    const path = '/api/internal/cart_products';

    return api({
      method: 'get',
      url: path,
      headers,
    });
  },
  change(productId: number, data: Partial<CartProduct>) {
    const path = `/api/internal/cart_products/change?product_id=${productId}`;

    return api({
      method: 'post',
      url: path,
      data,
      headers,
    });
  },
};

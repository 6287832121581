<template>
  <Dialog
    :open="modelValue"
    as="div"
    class="relative z-50"
    @close="emit('update:modelValue', false)"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />
    <div class="flex fixed inset-0 justify-center items-center p-4 text-gray-700">
      <DialogPanel class="flex flex-col p-5 w-full max-w-md bg-gray-50 rounded">
        <div class="flex justify-between mb-3 w-full">
          <DialogTitle class="font-serif text-xl md:text-2xl">
            Selecciona un archivo
          </DialogTitle>
          <button
            class="ml-auto focus:outline-none"
            @click="emit('update:modelValue', false)"
          >
            <XMarkIcon class="w-6 h-6" />
          </button>
        </div>
        <DialogDescription
          class="flex flex-col mb-5 text-base text-gray-700"
        >
          <p>
            Descarga el template de productos y completa la información solicitada:
          </p>
          <p class="ml-2 text-sm">
            <span class="font-semibold">- name (obligatorio):</span>
            <span> Nombre del producto</span>
          </p>
          <p class="ml-2 text-sm">
            <span class="font-semibold">- description (obligatorio):</span>
            <span>Descripción del producto</span>
          </p>
          <p class="ml-2 text-sm">
            <span class="font-semibold">- sku (opcional):</span>
            <span>SKU del producto, en caso de no incluir uno se asignará automáticamente</span>
          </p>
          <p class="ml-2 text-sm">
            <span class="font-semibold">- unitary_price_cents (obligatorio):</span>
            <span>Precio a público sugerido</span>
          </p>
          <p class="ml-2 text-sm">
            <span class="font-semibold">- wholesale_price_cents (obligatorio):</span>
            <span>Precio mayorista</span>
          </p>
          <p class="ml-2 text-sm">
            <span class="font-semibold">- minimum_purchase_quantity (opcional):</span>
            <span>Cantidad mínima por producto</span>
          </p>
          <p class="ml-2 text-sm">
            <span class="font-semibold">- weight (opcional):</span>
            <span>Peso aproximado del producto</span>
          </p>
          <p class="mb-2 ml-2 text-sm">
            <span class="font-semibold">- weight_unit (opcional):</span>
            <span>Unidad de peso del producto, puede ser 'grams' o 'kilograms'</span>
          </p>
          <p class="mb-2 ml-2 text-sm">
            <span class="font-semibold">- specifications (opcional):</span>
            <span>Dimensiones o cualquier dato relevante</span>
          </p>
          <p class="ml-2 text-sm font-semibold">
            *Nota que no debes modificar los títulos del archivo excel.
          </p>
          <p class="mb-2 ml-2 text-sm font-semibold">
            **Debes subir el archivo en formato excel (.xlsx)
          </p>
          <downloadable-files-form
            action="/products/download_products_template"
            label="Descargar Template"
            classes="px-2 py-1 text-sm rounded-md bg-lk-green text-white"
          />
        </DialogDescription>
        <file-uploader
          :max-number-of-files="1"
          :file-type="['.xlsx']"
          @update-file-paths="(value) => filesPath = value"
        />
        <lokal-loading
          v-if="loading"
          class="self-center"
          :loading="loading"
        />
        <lokal-button
          v-else
          action-name="Subir Archivo"
          size="normal"
          :action="Object.keys(filesPath).length === 0 ? 'cancel' : 'confirm'"
          :disabled="Object.keys(filesPath).length === 0"
          @click="uploadFile"
        />
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/solid';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue';
import { ref } from 'vue';
import productsApi from 'api/products';
import fileUploader from './file-uploader.vue';
import downloadableFilesForm from './downloadable-files-form.vue';
import lokalLoading from './lokal-loading.vue';

interface LokalConfirmationDialogProp {
  modelValue: boolean,
  maker: Maker
}
const props = withDefaults(defineProps<LokalConfirmationDialogProp>(), {
  modelValue: false,
});

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void, (e: 'add-message', value: string[]): void
}>();

const loading = ref(false);
const filesPath = ref({} as { [key: string]: string});
async function uploadFile() {
  loading.value = true;
  const fileKey = Object.keys(filesPath.value)[0];
  await productsApi.uploadProductsFromFile(filesPath.value[fileKey], props.maker.slug);
  emit('add-message', ['notice', 'Productos subidos correctamente']);
  emit('add-message', ['notice', 'Debes esperar unos minutos y luego recargar la página para ver los cambios']);
  loading.value = false;
  emit('update:modelValue', false);
}
</script>

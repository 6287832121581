<template>
  <div
    class="flex last:mb-0 w-full"
    :class="{ 'justify-between': inCheckout }"
  >
    <lokal-loading
      v-if="isLoading || isRefetching"
      :loading="isLoading || isRefetching"
      size="xs"
    />
    <template v-else-if="maker">
      <template v-if="inCheckout">
        <p>
          <span>
            {{ makerShopPickupAddressId ? 'Retiro en' : 'Despacho ' }}
          </span>
          <span v-if="hasFreeShipping">gratis</span>
          {{ maker.name }}
        </p>
        <p>
          <span v-if="!destinationAddress?.communeName">
            Sin dirección seleccionada
          </span>
          <template v-else>
            <span v-if="makerShopPickupAddressId">
              {{ useFormatCurrency(cartStore.shippingCostPerMaker[props.makerId]) }}
            </span>
            <template v-else>
              <span :class="{ 'line-through mr-1': hasFreeShipping || withSubsidize }">
                <span>{{ useFormatCurrency(cartStore.originalShippingCostPerMaker[props.makerId] || 5000) }}</span>
              </span>
              <span v-if="hasFreeShipping">$0</span>
              <span v-else-if="withSubsidize">
                <span>{{ useFormatCurrency(cartStore.shippingCostPerMaker[props.makerId]) }}</span>
              </span>
            </template>
          </template>
        </p>
      </template>
      <template v-else>
        <p class="mr-2">
          <slot
            name="shipping-message"
            :valid-maker-destination="validMakerDestination"
          >
            <span v-if="validMakerDestination">Costo de despacho:</span>
            <span v-else>Dirección de despacho sin despacho</span>
          </slot>
        </p>
        <p v-if="validMakerDestination">
          <span v-if="makerShopPickupAddressId">
            {{ useFormatCurrency(cartStore.shippingCostPerMaker[props.makerId]) }}
          </span>
          <template v-else>
            <span :class="{ 'line-through mr-1': hasFreeShipping || withSubsidize }">
              <span>{{ useFormatCurrency(cartStore.originalShippingCostPerMaker[props.makerId] || 5000) }}</span>
            </span>
            <span v-if="hasFreeShipping">$0</span>
            <span v-else-if="withSubsidize">
              <span>{{ useFormatCurrency(cartStore.shippingCostPerMaker[props.makerId]) }}</span>
            </span>
          </template>
        </p>
      </template>
    </template>
  </div>
</template>
<script setup lang="ts">
import { computed, watch } from 'vue';
import { values, sum, round, max, min, orderBy } from 'lodash';
import useCartStore from 'stores/cart';
import useFormatCurrency from 'components/use/format-currency';
import useMakerQuery from 'components/queries/maker-query';
import useMakerShippingQuotations from 'components/use/maker-shipping-quotations';
import useAddressesQuery from 'components/queries/addresses-query';
import useFreeShippingsQuery from 'components/queries/free-shippings-query';
import useValidDestinationQuery from 'components/queries/valid-destination-query';
import useMakerCodeDiscounts from 'components/use/maker-code-discounts';
import useCarriersQuery from '../queries/carriers-query';
import useShippingQuotationsQuery from '../queries/shipping-quotations-query';
import useMakerFreeShippingHelper from '../use/maker-free-shipping-helper';
import useSubsidizedShippings from '../use/subsidized-shippings';
import useMakerSubsidizedShippingsHelper from '../use/maker-subsidized-shippings-helper';

const GRAMS_TO_KILOGRAMS = 1000;
const FACTOR = 1.1;
const DEFAULT_WEIGTH = 3;
const SUBSIDIZED_FACTOR = 2;

interface MakerShippingPriceInterface {
  makerId: number,
  destinationAddress: Partial<Address>,
  inCheckout?: boolean,
}

const props = withDefaults(defineProps<MakerShippingPriceInterface>(), {
  inCheckout: true,
});

const cartStore = useCartStore();
const computedMakerId = computed(() => props.makerId);
const { maker } = useMakerQuery(computedMakerId);
const destinationCommune = computed(() => props.destinationAddress.communeName as string);
const {
  addresses: makerAddresses,
} = useAddressesQuery(computedMakerId, computed(() => 'Maker'), computed(() => !!maker.value?.id));
const origineAddress = computed(() => {
  const defaultAddress = makerAddresses.value?.find((address) => address.id === maker.value?.defaultAddressId);
  if (defaultAddress) return defaultAddress;

  return makerAddresses.value?.[0];
});
const originCommune = computed(() => origineAddress.value?.communeName);
const carriersQueryParams = computed(() =>
  `q[maker_carriers_maker_id_eq]=${props.makerId}&q[maker_carriers_active_eq]=true`);
const { carriers, carriersQuery } = useCarriersQuery(carriersQueryParams);
const secondCarrierCodes = computed(() => carriers.value?.map((carrier: Carrier) => carrier.code));

function weightToKilograms(item: Item) {
  if (item.product.weightUnit === 'kilograms') return item.product.weight * item.quantity;

  return (item.product.weight * item.quantity) / GRAMS_TO_KILOGRAMS;
}

const freeShippingsQueryParams = computed(() => 'q[active_eq]=true');
const { freeShippingsQuery, orderedFreeShippings } = useFreeShippingsQuery(computedMakerId, freeShippingsQueryParams);

const { validDestination: posibleValidMakerDestination } = useValidDestinationQuery(
  computedMakerId, computed(() => 'Maker'), computed(() => props.destinationAddress?.communeId),
);
const validMakerDestination = computed(() => {
  if (posibleValidMakerDestination.value !== undefined) return posibleValidMakerDestination.value;

  return true;
});

const enabledShippingQuotationsQuery = computed(() => !!originCommune.value && !!destinationCommune.value &&
  !!maker.value?.id && !carriersQuery.isLoading.value && !freeShippingsQuery.isLoading.value &&
validMakerDestination.value);
const filteredItems = computed(() => values(cartStore.items).filter((item) => item.product.makerId === props.makerId));
const productWeights = computed(() => filteredItems.value.map((item) => weightToKilograms(item)));
const increasedCurrentWeight = computed(() => sum(productWeights.value) * FACTOR);
const currentWeight = computed(() => max([increasedCurrentWeight.value, DEFAULT_WEIGTH * FACTOR]) as number);
const { shippingQuotationsQuery, sortedShippingQuotations } = useShippingQuotationsQuery(
  originCommune, destinationCommune, currentWeight, enabledShippingQuotationsQuery,
);
const isLoading = computed(() => shippingQuotationsQuery.isLoading.value);
const isRefetching = computed(() => shippingQuotationsQuery.isRefetching.value);
const { shippingCost } = useMakerShippingQuotations(sortedShippingQuotations, secondCarrierCodes);

watch(() => props.destinationAddress, async () => {
  await shippingQuotationsQuery.refetch.value();
});

watch(isLoading, () => {
  cartStore.loadingShippingCostPerMaker[props.makerId] = isLoading.value;
});

const { productWholesalePriceWithDiscountCode } = useMakerCodeDiscounts(computedMakerId);

const computedDestinationRegionId = computed(() => props.destinationAddress.regionId as number);
const computedDestinationCommuneId = computed(() => props.destinationAddress.communeId as number);
const { hasFreeShipping, nextFreeShipping } = useMakerFreeShippingHelper(
  orderedFreeShippings, productWholesalePriceWithDiscountCode, computedDestinationRegionId,
  computedDestinationCommuneId,
);

const subsidizedShippingsQueryParams = computed(() => 'q[active_eq]=true');
const { subsidizedShippings } = useSubsidizedShippings(
  computedMakerId, subsidizedShippingsQueryParams, computed(() => true));
const orderedSubsidizedShippings = computed(() => orderBy(subsidizedShippings.value, 'subsidizeAmountCents', 'asc'));
const {
  hasSubsidizedShipping, currentSubsidizedShipping, nextSubsidizedShipping,
} = useMakerSubsidizedShippingsHelper(orderedSubsidizedShippings, productWholesalePriceWithDiscountCode);
const subsidizedMakerShippingAmount = computed(() => {
  if (currentSubsidizedShipping.value?.id) {
    return min([shippingCost.value, currentSubsidizedShipping.value?.subsidizedAmountCents]);
  }

  return 0;
});
const subsidizedLokalShippingAmount = computed(() => {
  if (currentSubsidizedShipping.value?.id) {
    const amountLeft = shippingCost.value - currentSubsidizedShipping.value.subsidizedAmountCents;

    return amountLeft > 0 ? min([amountLeft, currentSubsidizedShipping.value.subsidizedAmountCents]) : 0;
  }

  return 0;
});
const shippingCostWithSubsidize = computed(() => {
  if (currentSubsidizedShipping.value?.id) {
    if (currentSubsidizedShipping.value.kind === 'with_lokal') {
      return max([shippingCost.value - currentSubsidizedShipping.value.subsidizedAmountCents * SUBSIDIZED_FACTOR, 0]);
    }

    return max([shippingCost.value - currentSubsidizedShipping.value.subsidizedAmountCents, 0]);
  }

  return shippingCost.value;
});

const regionSubsidizedMinimum = computed(() => props.destinationAddress.regionSubsidizedMinimum as number);
const halfSubsidizedEnabled = computed(() => cartStore.itemsMakerIds.length > 1 &&
productWholesalePriceWithDiscountCode.value > regionSubsidizedMinimum.value);
const halfSubsidizedByLokalShippingCost = computed(() => {
  if (shippingCost.value && halfSubsidizedEnabled.value) {
    return round(shippingCost.value * cartStore.subsidizedFactor);
  }

  return shippingCost.value;
});

const makerShopPickupAddressId = computed(() => !!cartStore.shopPickupAddressIds[props.makerId]);

const withSubsidize = computed(() => (halfSubsidizedEnabled.value && !nextSubsidizedShipping.value) ||
  hasSubsidizedShipping.value);

function setMakerShopPickupShippingCosts() {
  cartStore.shippingCostPerMaker[props.makerId] = 0;
  cartStore.originalShippingCostPerMaker[props.makerId] = 0;
  cartStore.makerShippingCostPerMaker[props.makerId] = 0;
  cartStore.lokalShippingCostPerMaker[props.makerId] = 0;
}

function setFreeShippingCosts() {
  cartStore.shippingCostPerMaker[props.makerId] = 0;
  cartStore.originalShippingCostPerMaker[props.makerId] = shippingCost.value as number;
  if (nextFreeShipping.value?.id) {
    if (nextFreeShipping.value?.lokalPercentage === 0) {
      cartStore.makerShippingCostPerMaker[props.makerId] = shippingCost.value as number;
      cartStore.lokalShippingCostPerMaker[props.makerId] = 0;
    } else {
      cartStore.makerShippingCostPerMaker[props.makerId] = shippingCost.value / SUBSIDIZED_FACTOR;
      cartStore.lokalShippingCostPerMaker[props.makerId] = shippingCost.value / SUBSIDIZED_FACTOR;
    }
  } else {
    cartStore.makerShippingCostPerMaker[props.makerId] = 0;
    cartStore.lokalShippingCostPerMaker[props.makerId] = 0;
  }
}

function setSubsidizedShippingCosts() {
  cartStore.shippingCostPerMaker[props.makerId] = shippingCostWithSubsidize.value as number;
  cartStore.originalShippingCostPerMaker[props.makerId] = shippingCost.value as number;
  if (currentSubsidizedShipping.value?.id) {
    if (currentSubsidizedShipping.value?.kind === 'with_lokal') {
      cartStore.makerShippingCostPerMaker[props.makerId] = subsidizedMakerShippingAmount.value as number;
      cartStore.lokalShippingCostPerMaker[props.makerId] = subsidizedLokalShippingAmount.value as number;
    } else {
      cartStore.makerShippingCostPerMaker[props.makerId] = subsidizedMakerShippingAmount.value as number;
      cartStore.lokalShippingCostPerMaker[props.makerId] = 0;
    }
  }
}

function setHalfSubsidizedByLokalShippingCosts() {
  cartStore.shippingCostPerMaker[props.makerId] = halfSubsidizedByLokalShippingCost.value as number;
  cartStore.originalShippingCostPerMaker[props.makerId] = shippingCost.value as number;
  cartStore.makerShippingCostPerMaker[props.makerId] = 0;
  cartStore.lokalShippingCostPerMaker[props.makerId] = shippingCost.value -
    halfSubsidizedByLokalShippingCost.value as number;
}

function setShippingCost() {
  if (makerShopPickupAddressId.value) {
    setMakerShopPickupShippingCosts();
  } else if (hasFreeShipping.value) {
    setFreeShippingCosts();
  } else if (hasSubsidizedShipping.value) {
    setSubsidizedShippingCosts();
  } else if (halfSubsidizedEnabled.value && !nextSubsidizedShipping.value?.id) {
    setHalfSubsidizedByLokalShippingCosts();
  } else {
    cartStore.shippingCostPerMaker[props.makerId] = shippingCost.value as number;
    cartStore.originalShippingCostPerMaker[props.makerId] = shippingCost.value as number;
    cartStore.makerShippingCostPerMaker[props.makerId] = 0;
    cartStore.lokalShippingCostPerMaker[props.makerId] = 0;
  }
}

watch(shippingCost, setShippingCost);
watch(hasFreeShipping, setShippingCost);
watch(hasSubsidizedShipping, setShippingCost);
watch(productWholesalePriceWithDiscountCode, setShippingCost);
watch(makerShopPickupAddressId, setShippingCost);
</script>

<template>
  <transition
    enter-active-class="transition-transform duration-300"
    enter-from-class="-translate-x-full sm:translate-x-full"
    enter-to-class="translate-x-0"
    leave-active-class="transition-transform duration-300"
    leave-from-class="translate-x-0"
    leave-to-class="-translate-x-full sm:translate-x-full"
  >
    <aside
      id="maker-filter-sidebar"
    >
      <div class="px-2 h-full sm:py-6 sm:mx-5 md:py-10 ">
        <div
          v-if="crossEnabled"
          class="flex w-full"
        >
          <button
            class="mt-2 ml-auto"
            @click="menuStore.showProductFilterSidebar = !menuStore.showProductFilterSidebar"
          >
            <XMarkIcon class="w-5 h-5" />
          </button>
        </div>
        <div class="flex items-center mb-2 text-lg md:text-2xl">
          <FunnelIcon
            class="mr-2 w-5 h-5 text-lk-green fill-lk-green md:w-6 md:h-6"
          />
          <p>Filtros</p>
        </div>
        <div class="flex flex-wrap items-center">
          <button
            v-for="record in selectedCategories"
            :key="record.id"
            class="flex items-center p-1 mr-1 mb-1 text-sm text-white bg-lk-green rounded-md"
            @click="removeSelectedCategory(record)"
          >
            {{ record.name }}
            <XCircleIcon class="w-4 h-4 text-lk-green cursor-pointer fill-white" />
          </button>
          <button
            v-for="record in selectedLabels"
            :key="record.id"
            class="flex items-center p-1 mr-1 mb-1 text-sm text-white bg-lk-green rounded-md"
            @click="removeSelectedLabel(record)"
          >
            {{ record.name }}
            <XCircleIcon class="w-4 h-4 text-lk-green cursor-pointer fill-white" />
          </button>
        </div>
        <div class="flex flex-col mb-3" />
        <div class="w-full border-b" />
        <p class="my-2 text-base md:text-xl">
          Rápidos
        </p>
        <button
          v-if="sessionStore.user?.shopId"
          class="flex flex-col p-3 mb-2 w-full text-left bg-white rounded-md border md:bg-gray-50"
          :class="{ 'border-lk-green': showOnlyProductsWithFreeShippingToCurrentAddress }"
          type="button"
          @click="showOnlyProductsWithFreeShippingToCurrentAddress = !showOnlyProductsWithFreeShippingToCurrentAddress"
        >
          <div class="flex items-center w-full">
            <TruckIcon class="shrink-0 w-5 h-5 md:w-6 md:h-6" />
            <lokal-switch
              v-model="showOnlyProductsWithFreeShippingToCurrentAddress"
              class="ml-auto"
            />
          </div>
          <p>Despacho gratis a tu dirección</p>
        </button>
        <button
          class="flex flex-col p-3 mb-2 w-full text-left bg-white rounded-md border md:bg-gray-50"
          :class="{ 'border-lk-green': discountProducts }"
          type="button"
          @click="discountProducts = !discountProducts"
        >
          <div class="flex items-center w-full">
            <TagIcon class="shrink-0 w-5 h-5 md:w-6 md:h-6" />
            <lokal-switch
              v-model="discountProducts"
              class="ml-auto"
            />
          </div>
          <p>Descuentos</p>
        </button>
        <button
          class="flex items-center mb-2"
          @click="onylRecommendedProducts = !onylRecommendedProducts"
        >
          <div
            class="mr-2 w-4 h-4 rounded-md border"
            :class="{ 'bg-lk-green border-0 ease-in-out duration-300 transition': onylRecommendedProducts }"
          />
          <p class="text-left">
            Recomendados
          </p>
        </button>
        <div class="w-full border-b" />
        <template v-if="withMakerFilter">
          <p class="my-2 text-base md:text-xl">
            Marcas
          </p>
          <input
            v-model="makerSearchText"
            class="p-1 mb-2 w-full text-sm rounded-md border focus:border-lk-green focus:outline-none"
            placeholder="Buscar categoría"
          >
          <lokal-loading
            v-if="makersIsLoading"
            :loading="makersIsLoading"
            size="small"
          />
          <div
            v-else
            class="flex overflow-auto flex-col mb-2 w-full h-72"
          >
            <button
              v-for="maker in filteredMakers"
              :key="maker.slug"
              class="flex items-center"
              @click="updateSelectedMakers(maker)"
            >
              <div
                class="mr-2 w-4 h-4 rounded-md border"
                :class="{ 'bg-lk-green border-0': selectedMakerIds.includes(maker.slug) }"
              />
              <p class="self-start text-left hover:text-lk-green">
                {{ maker.name }}
              </p>
            </button>
          </div>
          <div class="w-full border-b" />
        </template>
        <template v-if="makerSlug">
          <p class="my-2 text-base md:text-xl">
            Colecciones
          </p>
          <input
            v-model="categorySearchText"
            class="p-1 mb-2 w-full text-sm rounded-md border focus:border-lk-green focus:outline-none"
            placeholder="Buscar colleción"
          >
          <lokal-loading
            v-if="isCollectionsLoading"
            :loading="isCollectionsLoading"
            size="small"
          />
          <div
            v-else
            class="flex flex-col mb-2 w-full"
          >
            <button
              v-for="collection in filteredCollections"
              :key="collection.id"
              class="flex items-center"
              @click="updateSelectedCollections(collection)"
            >
              <div
                class="mr-2 w-4 h-4 rounded-md border"
                :class="{ 'bg-lk-green border-0 ease-in-out duration-300 transition':
                  selectedCollectionIds.includes(collection.id) }"
              />
              <p class="self-start text-left hover:text-lk-green">
                {{ collection.name }}
              </p>
            </button>
          </div>
        </template>
        <p class="my-2 text-base md:text-xl">
          Categorías
        </p>
        <input
          v-model="categorySearchText"
          class="p-1 mb-2 w-full text-sm rounded-md border focus:border-lk-green focus:outline-none"
          placeholder="Buscar categoría"
        >
        <lokal-loading
          v-if="categoryQuery.isLoading.value"
          :loading="categoryQuery.isLoading.value"
          size="small"
        />
        <div
          v-else
          class="flex flex-col mb-2 w-full"
        >
          <button
            v-for="category in filteredCategories"
            :key="category.id"
            class="flex items-center"
            @click="updateSelectedCategories(category)"
          >
            <div
              class="mr-2 w-4 h-4 rounded-md border"
              :class="{
                'bg-lk-green border-0 ease-in-out duration-300 transition': selectedCategoryIds.includes(category.id)
              }"
            />
            <p class="self-start text-left hover:text-lk-green">
              {{ category.name }}
            </p>
          </button>
        </div>
        <div class="w-full border-b" />
        <p class="my-2 text-base md:text-xl">
          Etiquetas
        </p>
        <input
          v-model="labelSearchText"
          class="p-1 mb-2 w-full text-sm rounded-md border focus:border-lk-green focus:outline-none"
          placeholder="Buscar etiquetas"
        >
        <lokal-loading
          v-if="labelsQuery.isLoading.value"
          :loading="labelsQuery.isLoading.value"
          size="small"
        />
        <div
          v-else
          class="overflow-auto mb-2 h-72"
        >
          <button
            v-for="label in filteredLabels"
            :key="label.id"
            class="flex items-center"
            @click="updateSelectedLabels(label)"
          >
            <div
              class="mr-2 w-4 h-4 rounded-md border"
              :class="{
                'bg-lk-green border-0 ease-in-out duration-300 transition': selectedLabelIds.includes(label.id)
              }"
            />
            <div class="self-start hover:text-lk-green">
              {{ label.name }}
            </div>
          </button>
        </div>
        <div class="w-full border-b" />
      </div>
    </aside>
  </transition>
</template>
<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { useQuery } from 'vue-query';
import { sortBy } from 'lodash';
import { storeToRefs } from 'pinia';
import makersApi from 'api/makers';
import { FunnelIcon, XMarkIcon, XCircleIcon, TruckIcon, TagIcon } from '@heroicons/vue/24/outline';
import useGeneralMenuStore from 'stores/general-menu-store';
import useProductFilterStore from 'stores/product-filter-store';
import useSessionStore from 'stores/user-store';
import useUrlMethods from './use/url-methods';
import useCategoriesQuery from './queries/categories-query';
import LokalSwitch from './shared/lokal-switch.vue';
import useLabelsQuery from './queries/labels-query';
import useCollectionsQuery from './queries/collections-query';

interface ProductListAsideFiltersProps {
  withMakerFilter?: boolean,
  makerSlug?: string,
  crossEnabled?: boolean,
}

const props = withDefaults(defineProps<ProductListAsideFiltersProps>(), {
  makerSlug: '',
  withMakerFilter: true,
  çrossEnabled: false,
});

const sessionStore = useSessionStore();
const menuStore = useGeneralMenuStore();

const productFilterStore = useProductFilterStore();
const { selectedMakers, selectedCategories, selectedMakerIds, selectedCategoryIds,
  onylRecommendedProducts, showOnlyProductsWithFreeShippingToCurrentAddress, selectedLabels, selectedLabelIds,
  selectedCollections, selectedCollectionIds, discountProducts,
} = storeToRefs(productFilterStore);

const { getQueryParam } = useUrlMethods();

// use useMakersQuery
const makersQuery = useQuery('makers',
  () => makersApi.all(), { enabled: computed(() => !props.makerSlug && props.withMakerFilter) });
const makersIsLoading = computed(() => makersQuery.isLoading.value);
const makers = computed(() => makersQuery.data.value?.data.makers as Maker[]);

const colletionsQueryParams = computed(() => `q[maker_slug_eq]=${props.makerSlug}`);
const { collections, isCollectionsLoading, collectionsQuery } = useCollectionsQuery(
  colletionsQueryParams, computed(() => !!props.makerSlug),
);

const categoriesQueryParams = computed(() => (props.makerSlug ? `q[makers_slug_eq]=${props.makerSlug}` : ''));
const { categories, categoryQuery } = useCategoriesQuery(categoriesQueryParams);
const { labels, labelsQuery } = useLabelsQuery(categoriesQueryParams, computed(() => true));

const collectionSearchText = ref('');
const filteredCollections = computed(() => {
  if (collectionSearchText.value.length > 0) {
    return sortBy(collections.value?.filter((collection) =>
      collection.name.toLowerCase().includes(collectionSearchText.value.toLowerCase()))
    , (collection) => collection.name);
  }

  return sortBy(collections.value, (collection) => collection.name);
});

function updateSelectedCollections(collection: Collection) {
  if (selectedCollectionIds.value.includes(collection.id)) {
    selectedCollections.value = selectedCollections.value.filter((record) => record.name !== collection.name);
  } else {
    selectedCollections.value = [collection];
  }
}

const makerSearchText = ref('');
const filteredMakers = computed(() => {
  if (makerSearchText.value.length > 0) {
    return sortBy(makers.value.filter((maker) =>
      maker.name.toLowerCase().includes(makerSearchText.value.toLowerCase()))
    , (maker) => maker.name);
  }

  return sortBy(makers.value, (maker) => maker.name);
});

function updateSelectedMakers(maker: Maker) {
  if (selectedMakerIds.value.includes(maker.slug)) {
    selectedMakers.value = selectedMakers.value.filter((record) => record.slug !== maker.slug);
  } else {
    selectedMakers.value.push(maker);
  }
}

const categorySearchText = ref('');
const filteredCategories = computed(() => {
  if (categorySearchText.value.length > 0) {
    return sortBy(categories.value?.filter((category) =>
      category.name.toLowerCase().includes(categorySearchText.value.toLowerCase()))
    , (category) => category.priority);
  }

  return sortBy(categories.value, (category) => category.priority);
});

function updateSelectedCategories(category: Category) {
  if (selectedCategoryIds.value.includes(category.id)) {
    selectedCategories.value = selectedCategories.value.filter((record) => record.id !== category.id);
  } else {
    selectedCategories.value.push(category);
  }
}

const labelSearchText = ref('');
const filteredLabels = computed(() => {
  if (labelSearchText.value.length > 0) {
    return sortBy(labels.value?.filter((label) =>
      label.name.toLowerCase().includes(labelSearchText.value.toLowerCase()))
    , (label) => label.name);
  }

  return sortBy(labels.value, (label) => label.name);
});

function updateSelectedLabels(label: Label) {
  if (selectedLabelIds.value.includes(label.id)) {
    selectedLabels.value = selectedLabels.value.filter((record) => record.id !== label.id);
  } else {
    selectedLabels.value.push(label);
  }
}

function setSelectedMakersFromQueryParam() {
  const makerIds = getQueryParam('maker_ids')?.split(',');
  if (makers.value?.length && !!makerIds && makerIds.length > 0) {
    selectedMakers.value = makers.value.filter((maker) => makerIds.includes(maker.slug));
  }
}

function setSelectedCollectionsFromQueryParam() {
  const collectionIds = getQueryParam('collection_ids')?.split(',');
  if (collections.value?.length && !!collectionIds && collectionIds.length > 0) {
    selectedCollections.value = collections.value.filter((collection) => collectionIds.includes(`${collection.id}`));
  }
}

function setSelectedCategoriesFromQueryParam() {
  const categoryIds = getQueryParam('category_ids');
  if (categories.value?.length && !!categoryIds && categoryIds.length > 0) {
    selectedCategories.value = categories.value?.filter((category) => categoryIds.includes(`${category.id}`));
  }
}

function setSelectedLabelsFromQueryParam() {
  const labelIds = getQueryParam('label_ids');
  if (labels.value?.length && !!labelIds && labelIds.length > 0) {
    selectedLabels.value = labels.value?.filter((subCategory) => labelIds.includes(`${subCategory.id}`));
  }
}

onMounted(async () => {
  await Promise.all([
    makersQuery.suspense(), collectionsQuery.suspense(), categoryQuery.suspense(), labelsQuery.suspense(),
  ]);
  setSelectedMakersFromQueryParam();
  setSelectedCollectionsFromQueryParam();
  setSelectedCategoriesFromQueryParam();
  setSelectedLabelsFromQueryParam();
});

function removeSelectedCategory(category: Category) {
  selectedCategories.value = selectedCategories.value.filter((record) => record.id !== category.id);
}

function removeSelectedLabel(label: Label) {
  selectedLabels.value = selectedLabels.value.filter((record) => record.id !== label.id);
}
</script>

<template>
  <div
    class="flex flex-col mb-3 w-full text-sm text-gray-700 rounded-md border md:text-base"
    :class="{ 'border-red-500': incidenceReport.status === 'incidence_in_progress',
              'border-gray-300': incidenceReport.status === 'incidence_resolved' }"
    :disabled="incidenceReport.status !== 'incidence_resolved'"
    @click="showIncideceReport = !showIncideceReport"
  >
    <component
      :is="incidenceReport.status === 'incidence_resolved' ? 'button' : 'div'"
      class="flex items-center p-3 rounded-md"
      :class="{ 'hover:bg-gray-50': incidenceReport.status === 'incidence_resolved' }"
    >
      <ExclamationCircleIcon
        class="shrink-0 mr-1 w-4 h-4 md:mr-2 md:w-5 md:h-5"
      />
      <p
        v-if="incidenceReport.status === 'incidence_in_progress'"
        class="text-base md:text-lg"
      >
        Reporte de incidencia
      </p>
      <p
        v-else
        class="text-base md:text-lg"
      >
        Incidencia resuelta
      </p>
      <ChevronDownIcon
        v-if="incidenceReport.status === 'incidence_resolved'"
        class=" shrink-0 ml-auto w-4 h-4 md:w-5 md:h-5"
        :class="{ 'transform rotate-180 ease-linear duration-150': showIncideceReport,
                  'transform ease-linear duration-150': !showIncideceReport }"
      />
    </component>
    <div
      v-if="showIncideceReport || incidenceReport.status === 'incidence_in_progress'"
      class="flex flex-col px-3 pb-3"
    >
      <div class="flex items-center space-x-1">
        <span
          v-for="(tag, index) in incidenceReport.tags"
          :key="`tag-${index}`"
          class="p-1 px-2 text-xs text-white bg-lk-light-purple rounded-full md:text-sm"
        >
          {{ tag }}
        </span>
      </div>
      <div
        class="mb-2 font-light"
        v-html="incidenceReport?.description"
      />
      <div class="flex flex-col mb-2">
        <p class="mb-2">
          Imágenes
        </p>
        <div class="flex flex-wrap items-center">
          <img
            v-for="image in incidenceReport.images"
            :key="image.id"
            :src="image.derivatives?.jpgMd?.url"
            class="shrink-0 mr-2 w-32 h-32"
          >
        </div>
      </div>
      <div class="flex flex-col">
        <p class="mb-2">
          Documentos
        </p>
        <div
          v-for="document in incidenceReport.documents"
          :key="document.id"
          class="flex items-center space-x-2"
        >
          <DocumentTextIcon class="shrink-0 w-4 h-4 md:w-5 md:h-5" />
          <a
            :href="document.documentUrl"
            target="_blank"
            class="font-light hover:underline"
          >
            {{ document.documentData?.metadata?.filename }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { ChevronDownIcon, DocumentTextIcon, ExclamationCircleIcon } from '@heroicons/vue/24/outline';

defineProps<{
  incidenceReport: IncidenceReport
}>();

const showIncideceReport = ref(false);
</script>

<template>
  <div class="flex flex-col items-center mb-10 w-full h-full text-gray-700 md:px-5 lg:px-10 xl:max-w-7xl">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-start text-xl text-gray-700 md:text-2xl lg:text-3xl">
        <span>Facturas por despachos gratis</span>
      </p>
    </div>
    <div class="flex flex-col py-5 px-4 w-full h-full bg-white rounded-md md:py-5 md:px-10">
      <p class="mb-3 text-base md:text-lg">
        Detalles
      </p>
      <lokal-datepicker
        v-model="datePicked"
        :auto-apply="true"
        :range="false"
        class="mb-3"
        :month-picker="true"
      />
      <downloadable-files-form
        :action="`/makers/${props.maker.id}/free_shipping_excel?${queryParams}`"
        label="Descargar excel"
        class="w-full text-sm text-center text-lk-green hover:text-white hover:bg-lk-green rounded-md border border-lk-green md:w-auto md:text-center"
        classes="w-full p-2"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import downloadableFilesForm from './shared/downloadable-files-form.vue';
import lokalDatepicker from './shared/lokal-datepicker.vue';

const props = defineProps<{
  maker: Maker
}>();

const datePicked = ref({ month: new Date().getMonth(), year: new Date().getFullYear() });
const queryParams = computed(() => `month=${datePicked.value.month + 1}&year=${datePicked.value.year}`);
</script>

<template>
  <Dialog
    class="relative z-50"
    :open="modelValue"
    @close="emit('update:modelValue', false)"
  >
    <div
      class="fixed inset-0 bg-black/30"
      aria-hidden="true"
    />
    <div class="flex fixed inset-0 justify-center items-center p-2 text-gray-700">
      <DialogPanel class="flex flex-col p-3 w-full max-w-md text-sm bg-gray-50 rounded md:p-5 md:text-base">
        <button
          class="ml-auto focus:outline-none"
          @click="emit('update:modelValue', false)"
        >
          <XMarkIcon class="w-5 h-5 fill-gray-700" />
        </button>
        <DialogTitle class="mb-3 w-full font-serif text-2xl text-center md:text-3xl">
          Crear Retiro
        </DialogTitle>
        <DialogDescription class="flex items-center mb-3 space-x-2 text-sm text-gray-700 md:text-base">
          Podrás generar un retiro diario para los pedidos de Lokal
        </DialogDescription>
        <lokal-loading
          v-if="isLoading"
          class="self-center"
          :loading="isLoading"
        />
        <form
          v-else
          class="flex flex-col text-gray-700"
          @submit="onSubmit"
        >
          <p>Selecciona la dirección de retiro:</p>
          <div class="flex flex-col">
            <Field
              v-model="selectedWarehouseId"
              name="carrierRecord"
              label="carrier"
              hidden
              :rules="{ required: true }"
            />
            <lokal-generic-combobox
              v-model="selectedWarehouse"
              class="mb-2"
              :options="warehouses"
              :get-option-key="(option: Warehouse) => option.id"
              :get-option-label="(option: Warehouse) => option.address"
              options-label="Selecciona una direción de retiro"
              :multiple="false"
            />
          </div>
          <p>Selecciona un carrier:</p>
          <div class="flex flex-col">
            <Field
              v-model="selectedCarrierId"
              name="carrierRecord"
              label="carrier"
              hidden
              :rules="{ required: true }"
            />
            <lokal-generic-combobox
              v-model="selectedCarrier"
              :get-option-key="(option: Carrier) => option.id"
              :get-option-label="(option: Carrier) => option.name"
              :options="carriers"
              options-label="Selecciona un carrier"
              :multiple="false"
              class="mb-2"
            />
          </div>
          <p>Fecha de retiro:</p>
          <lokal-datepicker
            v-model="form.pickupDate"
            :min-date="minDate"
            :auto-apply="true"
            :range="false"
            class="mb-2"
          />
          <p>Observaciones</p>
          <Field
            v-model="form.information"
            as="textarea"
            class="p-2 mb-3 h-14 font-light rounded-sm focus:outline-none"
            :rules="{ required: false }"
            name="text"
          />
          <lokal-loading
            v-if="createPickupMutation.isLoading.value"
            :loading="createPickupMutation.isLoading.value"
            class="self-center"
          />
          <div
            v-else
            class="flex flex-col items-center w-full"
          >
            <button
              class="p-2 mb-2 w-full text-sm text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green md:text-base"
              type="submit"
              @click="handleSubmit"
            >
              Generar retiro
            </button>
            <button
              class="text-xs hover:underline md:text-sm"
              @click="emit('update:modelValue', false)"
            >
              Cancelar
            </button>
          </div>
        </form>
      </DialogPanel>
    </div>
  </Dialog>
</template>
<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { ref, computed } from 'vue';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue';
import { useMutation, useQuery } from 'vue-query';
import { useForm, Field } from 'vee-validate';
import enviamePickupsApi from 'api/enviamePickups';
import warehousesApi from 'api/warehouses';
import useMessageStore from 'stores/message-store';
import LokalGenericCombobox from './shared/lokal-generic-combobox.vue';
import useCarriersQuery from './queries/carriers-query';
import LokalDatepicker from './shared/lokal-datepicker.vue';

interface EnviamePickupsFormProps {
  maker: Maker
  modelValue: boolean
}
const props = withDefaults(defineProps<EnviamePickupsFormProps>(), {
  modelValue: false,
});

const messageStore = useMessageStore();

const carriersQueryParams = computed(() =>
  `q[maker_carriers_maker_id_eq]=${props.maker.id}&q[maker_carriers_active_eq]=true`);
const { carriers, carriersQuery } = useCarriersQuery(carriersQueryParams);
const warehousesQuery = useQuery(['warehouses', props.maker.id], () => warehousesApi.index(props.maker.id));
const warehouses = computed(() => warehousesQuery.data.value?.data.warehouses as Warehouse[]);
const isLoading = computed(() => carriersQuery.isLoading.value ||
  warehousesQuery.isLoading.value);

const selectedWarehouse = ref({} as Warehouse);
const selectedWarehouseId = computed(() => selectedWarehouse.value.id);
const selectedCarrier = ref({} as Carrier);
const selectedCarrierId = computed(() => selectedCarrier.value.id);

const minDate = computed(() => {
  const maximumHours = 10;
  const maximumMinutes = 30;
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  if (hours > maximumHours || (hours === maximumHours && minutes > maximumMinutes)) {
    now.setDate(now.getDate() + 1);
  }

  return now;
});

const form = ref({
  pickupDate: minDate.value,
  numberOfPackages: 0,
  totalWeight: 0,
  carrierCodes: '',
  information: '',
});

const emit = defineEmits<{(e: 'update:modelValue', value: boolean): void, (e: 'reload-pickups'): void}>();

const createPickUpData = computed(() => ({
  makerId: props.maker.id,
  carrierId: selectedCarrierId.value,
  warehouseId: selectedWarehouse.value.id,
  packageQty: 3,
  weight: 31,
  pickupDate: form.value.pickupDate.toISOString().split('T')[0], // YYYY-MM-DD
  rangeTime: 'PM',
  size: 'l',
  information: form.value.information,
}));
const createPickupMutation = useMutation(
  () => enviamePickupsApi.create(createPickUpData.value),
  {
    onSuccess: () => {
      messageStore.kind = 'success';
      messageStore.message = 'Retiro creado con éxito';
      emit('reload-pickups');
      emit('update:modelValue', false);
    },
  },
);

const { handleSubmit } = useForm();
const onSubmit = handleSubmit(() => {
  createPickupMutation.mutate();
});
</script>

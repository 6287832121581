<template>
  <div class="flex flex-col items-center mb-10 w-full h-full md:px-5 md:max-w-7xl lg:px-10">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-start text-xl text-gray-700 md:text-2xl lg:text-3xl">
        <span>Información privada</span>
      </p>
    </div>
    <div class="flex flex-col py-3 px-2 w-full text-gray-700 bg-white rounded-md md:py-5 md:px-10">
      <div class="flex flex-col w-full">
        <p class="mb-1 text-base font-semibold md:text-lg">
          Contacto
        </p>
        <p class="mb-1 ml-0">
          Nombre de contacto
        </p>
        <Field
          v-model="contactName"
          name="contactName"
          class="p-2 w-full text-sm rounded-md border focus:border border-lk-light-gray focus:border-lk-green focus:outline-none"
          @change="updateMaker({ contactName })"
        />
        <p class="mb-1 ml-0">
          Número de contacto
        </p>
        <contact-number-input v-model="contactNumber" />
        <p class="mb-1 ml-0">
          Email de contacto
        </p>
        <div class="flex flex-col mb-2 w-full ">
          <Field
            v-model="contactEmail"
            name="contactEmail"
            class="p-2 w-full text-sm rounded-md border focus:border border-lk-light-gray focus:border-lk-green focus:outline-none"
            :class="{ 'border-red-500': Object.keys(errors).length > 0 }"
            :rules="{ email: true, required: false }"
            @change="updateMaker({ contactEmail })"
          />
          <ErrorMessage
            class="text-xs text-red-500 md:text-sm"
            name="contactEmail"
          />
        </div>
        <lokal-loading
          v-if="isLoading"
          :loading="isLoading"
          size="xs"
        />
        <template v-else-if="withWarehousePickup">
          <p class="mb-1 text-base font-semibold md:text-lg">
            Bodega
          </p>
          <p class="mb-1 ml-0">
            ¿Retiro en bodega?
          </p>
          <p class="mb-1 ml-0 font-light">
            {{ withWarehousePickup ? 'Sí' : 'No' }}
          </p>
          <p class="mb-1 ml-0">
            Dirección de bodega
          </p>
          <p class="mb-2 ml-0 font-light">
            {{ addresses[0]?.address }}, {{ addresses[0]?.communeName }}, {{ addresses[0]?.regionName }}
          </p>
        </template>
        <p class="mb-1 text-base font-semibold md:text-lg">
          Condiciones comerciales
        </p>
        <template v-if="maker.specialPaymentConditions">
          <p class="mb-1 ml-0">
            Condiciones de pago
          </p>
          <p class="mb-1 ml-0 font-light">
            {{ maker.specialPaymentConditions }}
          </p>
        </template>
        <template v-else>
          <lokal-tooltip
            main-label="Número máximo de días de pago"
            class="mb-1"
            :information="maxPaymentDaysInformation"
            button-classes="flex items-center space-x-1"
            information-classes="w-60 p-1 md:p-2"
            position-options="left"
          />
          <p class="mb-1 ml-0 font-light">
            {{ maker.maxPaymentDays }}
          </p>
        </template>
        <lokal-tooltip
          main-label="Comisión de apertura"
          class="mb-1"
          :information="openningCommissionInformation"
          button-classes="flex items-center space-x-1"
          information-classes="w-60 p-1 md:p-2"
          position-options="left"
        />
        <p class="mb-1 ml-0 font-light">
          {{ maker.openingCommission }}%
        </p>
        <lokal-tooltip
          main-label="Comisión recurrente"
          class="mb-1"
          :information="recurrentCommissionInformation"
          button-classes="flex items-center space-x-1"
          information-classes="w-60 p-1 md:p-2"
          position-options="left"
        />
        <p class="mb-1 ml-0 font-light">
          {{ maker.recurrentCommission }}%
        </p>
        <lokal-tooltip
          main-label="Comisión referido"
          class="mb-1"
          :information="referralCommissionInformation"
          button-classes="flex items-center space-x-1"
          information-classes="w-60 p-1 md:p-2"
          position-options="left"
        />
        <p class="mb-1 ml-0 font-light">
          {{ maker.referredCommission }}%
        </p>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { Field, ErrorMessage, useForm } from 'vee-validate';
import { useQuery, useMutation } from 'vue-query';
import makersApi from 'api/makers';
import addressesApi from 'api/addresses';
import useMessageStore from 'stores/message-store';
import LokalTooltip from './shared/lokal-tooltip.vue';
import ContactNumberInput from './shared/contact-number-input.vue';

const props = defineProps<{
  maker: Maker;
}>();

const { errors } = useForm();

const messageStore = useMessageStore();
const contactName = ref(props.maker.contactName || '');
const contactNumber = ref(props.maker.contactNumber || '');
const contactEmail = ref(props.maker.contactEmail || '');
const addressQuery = useQuery(
  ['addresses', props.maker.id], () => addressesApi.index(props.maker.id, 'Maker'),
);
const isLoading = computed(() => addressQuery.isLoading.value);
const addresses = computed(() => addressQuery.data.value?.data?.addresses as Address[]);

const makerMutation = useMutation(
  (data: Record<string, string>) => makersApi.update(props.maker.id, data),
  {
    onSuccess: (_, variables) => {
      let message = 'Dataos actualizados correctamente:\n';
      Object.keys(variables).forEach((key) => {
        message += `Nuevo valor: ${variables[key]}\n`;
      });
      messageStore.kind = 'success';
      messageStore.message = message;
    },
    onError: () => {
      messageStore.kind = 'error';
      messageStore.message = 'Hubo un error al actualizar los datos';
    },
  },
);

function updateMaker(data: { contactName?: string, contactNumber?: string, contactEmail?: string }) {
  makerMutation.mutate(data);
}

watch(contactNumber, () => {
  updateMaker({ contactNumber: `56${contactNumber.value}` });
});

const withWarehousePickup = computed(() =>
  ['with_pickup', 'only_summary'].includes(props.maker.warehousePickupCondition));

const openningCommissionInformation = computed(() => 'Comisión que se cobra en la primera compra de cada tienda.');
const recurrentCommissionInformation = computed(() =>
  'Comisión que se cobra desde las segunda compra en adelante de cada tienda.');
const referralCommissionInformation = computed(() =>
  `Las ventas a tiendas referidas de tu marca tienen comisión ${props.maker.referredCommission}.`);
const maxPaymentDaysInformation = computed(() => 'Plazo máximo (en días) para que Lokal te pague el pedido.');
</script>

import { csrfToken } from '@rails/ujs';
import { ref } from 'vue';
import api from './index';

declare interface ShipmentDetail {
  subOrderId: number,
  nPackages: number,
  weight: number,
  height: number,
  width: number,
  length: number,
  volume: number
  contentDescription: string,
}

const token = ref(csrfToken());
let headers = {};
if (token.value) {
  headers = { 'X-CSRF-Token': token.value };
}

export default {
  create(data: ShipmentDetail) {
    const path = '/api/internal/shipment_details';

    return api({
      method: 'post',
      url: path,
      data,
      headers,
    });
  },

  generateAndCloseSummary(shipmentDetailId: number) {
    return api({
      method: 'post',
      url: `/api/internal/shipment_details/${shipmentDetailId}/generate_and_close_summary`,
      headers,
    });
  },
};

import { computed, type ComputedRef } from 'vue';

export default function useMakerFreeShippingHelper(
  orderedFreeShippings: ComputedRef<FreeShipping[]>, priceWithVolumeDiscount: ComputedRef<number>,
  regionId: ComputedRef<number>, communeId: ComputedRef<number>,
) {
  const filteredOrderedFreeShippings = computed(() => orderedFreeShippings.value?.filter((freeShipping) =>
    freeShipping.minimumAmountCents <= priceWithVolumeDiscount.value));
  const mappedShippings = computed(() => filteredOrderedFreeShippings.value?.map((freeShipping) => {
    if (freeShipping.regions.map((region) => region.id).includes(regionId.value)) {
      return true;
    } else if (
      freeShipping.communes.map((commune) => commune.id).includes(communeId.value)) {
      return true;
    }

    return false;
  }));
  const hasFreeShipping = computed(() => {
    if (!filteredOrderedFreeShippings.value ||
        (filteredOrderedFreeShippings.value?.length && filteredOrderedFreeShippings.value?.length === 0)) {
      return false;
    }

    return mappedShippings.value.some((mappedShippingValue) => mappedShippingValue === true);
  });
  const nextFreeShipping = computed(() => {
    if (!orderedFreeShippings.value || orderedFreeShippings.value.length === 0) return null;

    return orderedFreeShippings.value.find((freeShipping) => {
      if (freeShipping.regions.map((region) => region.id).includes(regionId.value)) {
        return freeShipping;
      } else if (
        freeShipping.communes.map((commune) => commune.id).includes(communeId.value)) {
        return freeShipping;
      }

      return null;
    });
  });

  return { hasFreeShipping, nextFreeShipping };
}

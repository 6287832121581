<template>
  <a
    class="flex relative flex-col items-center rounded-md hover:shadow-xl"
    :href="`/makers/${maker.slug}`"
  >
    <div class="flex overflow-hidden relative flex-col shrink-0 items-center w-full h-44 rounded-t-md hover:shadow-xl sm:h-48 md:h-52 lg:h-60 xl:h-72">
      <div
        :style="{
          'background-image': `url(${maker.image?.derivatives?.jpgMd?.url || greenKImage})` }"
        class="object-cover absolute w-full h-full bg-center bg-cover duration-300 ease-in-out hover:scale-105"
      />
      <p
        v-if="makerCreatedInTheLastMonth"
        class="z-10 self-start p-1 w-full text-xs text-center text-white bg-lk-light-green rounded-t-md md:p-2 md:text-sm"
      >
        Nueva
      </p>
      <p
        v-if="maker.activeDiscounts?.length"
        class="flex z-10 justify-center items-center self-end p-1 w-full text-xs text-white bg-lk-light-purple md:text-sm"
        :class="{ 'rounded-t-md': !makerCreatedInTheLastMonth }"
      >
        {{ maker.activeDiscounts[0].name }}: -{{ maker.activeDiscounts[0].totalDiscount }}%
      </p>
    </div>
    <div class="flex flex-col w-full h-full text-white bg-lk-green rounded-b-md">
      <p class="flex flex-col justify-center p-2 w-full text-base sm:px-3 md:text-lg">
        <span class="">{{ maker.name }}</span>
        <span
          v-if="sessionStore.user?.id"
          class="text-xs md:text-sm"
        >
          Mínimo: {{ useFormatCurrency(maker.minimumPurchaseAmountCents) }}
        </span>
        <span
          v-else
          class="text-xs md:text-sm"
        >
          Mínimo: <span class="blur">fake value</span>
        </span>
        <lokal-tooltip
          v-if="!isLoading && freeShippings.length && freeShippings.length > 0"
          :information="freeShippingsInformation"
          button-classes="flex items-center space-x-1"
          information-classes="w-60 p-1 md:p-2 text-xs md:text-sm"
          :position-options="(isMobile && !oddPosition) ? 'right' : 'left'"
        >
          <template #custom-content>
            <TruckIcon class="shrink-0 mr-1 w-3 h-3 text-white md:w-4 md:h-4" />
            <span class="text-xs md:text-sm">Despacho gratis</span>
          </template>
        </lokal-tooltip>
      </p>
    </div>
  </a>
</template>
<script setup lang="ts">
import { computed, inject } from 'vue';
import { TruckIcon } from '@heroicons/vue/24/outline';
import useSessionStore from 'stores/user-store';
import { orderBy } from 'lodash';
import greenKImage from 'assets/images/green-k.jpg';
import useFormatCurrency from './use/format-currency';
import lokalTooltip from './shared/lokal-tooltip.vue';
import useFreeShippingsQuery from './queries/free-shippings-query';

const props = withDefaults(defineProps<{
  maker: Maker,
  oddPosition?: boolean,
}>(), {
  oddPosition: false,
});

const sessionStore = useSessionStore();

const isMobile = inject('isMobile');
const makerCreatedInTheLastMonth = computed(() => {
  const today = new Date();
  const oneMonthAgo = new Date(today.setMonth(today.getMonth() - 1));
  const makerCreatedAt = new Date(props.maker.createdAt);

  return makerCreatedAt >= oneMonthAgo;
});

const freeShippingsQueryParams = computed(() => 'q[active_eq]=true');
const { freeShippings, freeShippingsQuery } = useFreeShippingsQuery(
  computed(() => props.maker.id), freeShippingsQueryParams);
const isLoading = computed(() => freeShippingsQuery.isLoading.value);

const includesCurrentAddress = computed(() => {
  let isIt = false;
  freeShippings?.value.forEach(freeShipping => {
    freeShipping.regions.forEach(region => {
      if (region.id === sessionStore.currentRegionId) isIt = true;
    });
    freeShipping.communes.forEach(commune => {
      if (commune.id === sessionStore.currentCommuneId) isIt = true;
    });
  });

  return isIt;
});
const includeCurrentAddressMessage = computed(() =>
  (includesCurrentAddress.value ? 'Incluye tu dirección' : 'No incluye tu dirección'));
const freeShippingsInformation = computed(() => {
  if (!freeShippings.value?.length) return '';

  const baseText = orderBy(freeShippings.value, 'minimumAmountCents', 'asc').map((freeShipping) => {
    const minimumPurchaseAmount = useFormatCurrency(freeShipping.minimumAmountCents);
    const regionDestinations = freeShipping.regions.map((region) => region.name).join(', ');
    const communeDestinations = freeShipping.communes.map((commune) => commune.name).join(', ');
    let text = `Monto mínimo ${minimumPurchaseAmount}: `;
    if (regionDestinations) {
      text += `${regionDestinations}`;
      if (communeDestinations) text += `, ${communeDestinations}`;
    } else if (communeDestinations) {
      text += `${communeDestinations}`;
    }

    return text;
  })?.join('<br>');

  return `${includeCurrentAddressMessage.value}<br>${baseText}`;
});
</script>

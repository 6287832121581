<template>
  <div class="flex flex-col text-sm md:text-base">
    <button
      v-if="subOrder.status === 'pending' && !subOrder.labelDocumentUrl"
      class="flex justify-center items-center p-1 w-full text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green sm:mr-auto sm:w-auto md:p-1.5 xl:p-2"
      @click.prevent.stop="shipmentDialogOpen = !shipmentDialogOpen"
      @mouseover="hoverOnButton = true"
      @mouseleave="hoverOnButton = false"
    >
      <CubeTransparentIcon
        class="mr-2 w-4 h-4 md:w-5 md:h-5"
        :class="{ 'fill-white': !hoverOnButton, 'fill-lk-green': hoverOnButton }"
      />
      <span>Confirmar</span>
    </button>
    <template v-else-if="subOrder.shipmentMethod === 'lokal'">
      <button
        v-if="(subOrder.status === 'confirmed' && !subOrder.shipmentDetailId)"
        class="flex justify-center items-center p-1 w-full text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green sm:mr-auto sm:w-auto md:p-1.5 xl:p-2"
        @click.prevent.stop="shipmentDialogOpen = !shipmentDialogOpen"
        @mouseover="hoverOnButton = true"
        @mouseleave="hoverOnButton = false"
      >
        <PlusIcon
          class="mr-2 w-4 h-4 md:w-5 md:h-5"
          :class="{ 'fill-white': !hoverOnButton, 'fill-lk-green': hoverOnButton }"
        />
        <span>Agregar detalles de envío</span>
      </button>
      <div
        v-else-if="subOrder.status === 'confirmed'"
        class="flex flex-col w-full sm:flex-row"
      >
        <a
          class="flex flex-wrap justify-center items-center p-1 w-full text-lk-green bg-white rounded-md border border-lk-green md:p-1.5 lg:w-auto xl:p-2"
          :class="{
            'hover:text-white hover:bg-lk-green cursor-pointer': subOrder.labelDocumentUrl,
            'cursor-default': !subOrder.labelDocumentUrl
          }"
          :href="subOrder.labelDocumentUrl"
          :disabled="!subOrder.labelDocumentUrl"
          target="_blank"
          @mouseover="hoverOnButton = true"
          @mouseleave="hoverOnButton = false"
          @click.stop
        >
          <PrinterIcon
            v-if="subOrder.labelDocumentUrl"
            class="shrink-0 mr-2 w-4 h-4 md:w-5 md:h-5"
            :class="{ 'fill-white': !hoverOnButton, 'fill-lk-green': hoverOnButton }"
          />
          <ClockIcon
            v-else
            class="shrink-0 mr-2 w-4 h-4 md:w-5 md:h-5"
            :class="{ 'fill-white': !hoverOnButton, 'fill-lk-green': hoverOnButton && subOrder.labelDocumentUrl }"
          />
          <span
            v-if="subOrder.labelDocumentUrl"
            class="text-center"
          >
            Descargar etiqueta
          </span>
          <span
            v-else
            class="text-center"
          >
            Etiqueta pendiente
          </span>
        </a>
        <template v-if="withWarehousePickup">
          <a
            v-if="subOrder.summaryDocumentUrl"
            class="flex flex-wrap justify-center items-center p-1 mt-1 w-full text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green cursor-pointer sm:mx-1 sm:mt-0 md:p-1.5 lg:w-auto xl:p-2"
            :href="subOrder.summaryDocumentUrl"
            target="_blank"
            @mouseover="hoverSummaryOnButton = true"
            @mouseleave="hoverSummaryOnButton = false"
            @click.stop
          >
            <PrinterIcon
              class="shrink-0 mr-2 w-4 h-4 md:w-5 md:h-5"
              :class="{ 'fill-white': !hoverSummaryOnButton, 'fill-lk-green': hoverSummaryOnButton }"
            />
            <span class="text-center">Descargar manifiesto</span>
          </a>
          <template v-else-if="subOrder.enviameId">
            <lokal-loading
              v-if="generateManifestMutation.isLoading.value"
              :loading="generateManifestMutation.isLoading.value"
              size="xs"
              class="self-center"
            />
            <button
              v-else
              class="flex flex-wrap justify-center items-center p-1 mt-1 w-full text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green cursor-pointer sm:mt-0 sm:mr-auto sm:ml-1 md:p-1.5 lg:w-auto xl:p-2"
              @mouseover="hoverSummaryOnButton = true"
              @mouseleave="hoverSummaryOnButton = false"
              @click.prevent.stop="generateManifestMutation.mutate"
            >
              <CogIcon
                class="shrink-0 mr-2 w-4 h-4 md:w-5 md:h-5"
                :class="{ 'fill-white': !hoverSummaryOnButton, 'fill-lk-green': hoverSummaryOnButton }"
              />
              <span class="text-center">Generar manifiesto</span>
            </button>
          </template>
          <p
            v-else
            class="flex flex-wrap justify-center items-center p-1 mt-1 w-full text-lk-green bg-white rounded-md border border-lk-green cursor-default sm:mt-0 sm:mr-auto sm:ml-1 md:p-1.5 lg:w-auto xl:p-2"
          >
            <ClockIcon class="shrink-0 mr-2 w-4 h-4 fill-white md:w-5 md:h-5" />
            <span class="text-center">Manifiesto pendiente</span>
          </p>
        </template>
        <button
          v-if="summaryExists && withWarehousePickup && !subOrder.signedSummaryImageUrl"
          class="flex flex-wrap justify-center items-center p-1 mt-1 w-full text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green cursor-pointer sm:mt-0 sm:mr-auto md:p-1.5 lg:w-auto xl:p-2"
          @mouseover="hoverUploadSummaryOnButton = true"
          @mouseleave="hoverUploadSummaryOnButton = false"
          @click.prevent.stop="showSubOrderSignedSummaryUploadDialog = !showSubOrderSignedSummaryUploadDialog"
        >
          <ArrowUpTrayIcon
            class="shrink-0 mr-2 w-4 h-4 md:w-5 md:h-5"
            :class="{ 'fill-white': !hoverUploadSummaryOnButton, 'fill-lk-green': hoverUploadSummaryOnButton }"
          />
          <span class="text-center">Subir manifiesto firmado</span>
        </button>
      </div>
      <button
        v-else-if="summaryExists && withWarehousePickup && !subOrder.signedSummaryImageUrl"
        class="flex flex-wrap justify-center items-center p-1 mt-1 w-full text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green cursor-pointer sm:mt-0 sm:mr-auto md:p-1.5 lg:w-auto xl:p-2"
        @mouseover="hoverUploadSummaryOnButton = true"
        @mouseleave="hoverUploadSummaryOnButton = false"
        @click.prevent.stop="showSubOrderSignedSummaryUploadDialog = !showSubOrderSignedSummaryUploadDialog"
      >
        <ArrowUpTrayIcon
          class="shrink-0 mr-2 w-4 h-4 md:w-5 md:h-5"
          :class="{ 'fill-white': !hoverUploadSummaryOnButton, 'fill-lk-green': hoverUploadSummaryOnButton }"
        />
        <span class="text-center">Subir manifiesto firmado</span>
      </button>
    </template>
    <template v-else-if="subOrder.shipmentMethod === 'maker'">
      <button
        v-if="(subOrder.status === 'confirmed' && !subOrder.makerShipmentDetailId)"
        class="flex justify-center items-center p-1 w-full text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green sm:mr-auto sm:w-auto md:p-1.5 xl:p-2"
        @click.prevent.stop="makerShipmentDialogOpen = !makerShipmentDialogOpen"
        @mouseover="hoverOnButton = true"
        @mouseleave="hoverOnButton = false"
      >
        <PlusIcon
          class="mr-2 w-4 h-4 md:w-5 md:h-5"
          :class="{ 'fill-white': !hoverOnButton, 'fill-lk-green': hoverOnButton }"
        />
        <span>Agregar detalles de envío</span>
      </button>
      <template v-else-if="subOrder.status === 'delivery_in_progress'">
        <lokal-loading
          v-if="updateSubOrderMutation.isLoading.value"
          :loading="updateSubOrderMutation.isLoading.value"
          size="small"
          class="self-center"
        />
        <button
          v-else
          class="flex justify-center items-center p-1 w-full text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green md:p-1.5 md:mr-auto md:w-auto xl:p-2"
          @click.prevent.stop="showDeliveredConfirmationDialog = !showDeliveredConfirmationDialog"
          @mouseover="hoverOnButton = true"
          @mouseleave="hoverOnButton = false"
        >
          <TruckIcon
            class="shrink-0 mr-2 w-4 h-4 md:w-5 md:h-5"
            :class="{ 'fill-white': !hoverOnButton, 'fill-lk-green': hoverOnButton }"
          />
          <span>Confirmar entrega</span>
        </button>
      </template>
    </template>
    <sub-order-shipment-dialog
      v-if="shipmentDialogOpen"
      v-model:is-open="shipmentDialogOpen"
      :sub-order="subOrder"
      :maker="maker"
      :sub-order-products="subOrderProducts"
      @update:sub-order="() => emit('update:subOrder')"
    />
    <sub-order-maker-shipment-dialog
      v-if="makerShipmentDialogOpen"
      v-model="makerShipmentDialogOpen"
      :sub-order="subOrder"
      @reload:sub-order="emit('update:subOrder')"
    />
    <lokal-confirmation-dialog
      v-if="showDeliveredConfirmationDialog"
      v-model="showDeliveredConfirmationDialog"
      :title="`¿Deseas confirmar que el pedido #${subOrder.id} fue entregado al cliente?`"
      description="Al confirmar el despacho del pedido, la tienda recibirá una notificación."
      event-name="confirm-delivered"
      @confirm-delivered="updateSubOrderMutation.mutate"
    />
    <sub-order-signed-summary-upload-dialog
      v-if="showSubOrderSignedSummaryUploadDialog"
      v-model="showSubOrderSignedSummaryUploadDialog"
      :sub-order="subOrder"
      @reload:sub-order="emit('update:subOrder')"
    />
  </div>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue';
import {
  PlusIcon, PrinterIcon, CubeTransparentIcon, ClockIcon, TruckIcon, CogIcon, ArrowUpTrayIcon,
} from '@heroicons/vue/24/outline';
import { useMutation } from 'vue-query';
import subOrdersApi from 'api/sub-orders';
import shipmentDetailsApi from 'api/shipment-details';
import useMessageStore from 'stores/message-store';
import subOrderShipmentDialog from './sub-order-shipment-dialog.vue';
import subOrderMakerShipmentDialog from './sub-order-maker-shipment-dialog.vue';
import LokalConfirmationDialog from './shared/lokal-confirmation-dialog.vue';
import SubOrderSignedSummaryUploadDialog from './sub-order-signed-summary-upload-dialog.vue';

const props = defineProps<{
  subOrder: SubOrder
  maker: Maker
  subOrderProducts: SubOrderProduct[]
}>();

const messageStore = useMessageStore();

const shipmentDialogOpen = ref(false);
const makerShipmentDialogOpen = ref(false);
const showDeliveredConfirmationDialog = ref(false);
const showSubOrderSignedSummaryUploadDialog = ref(false);
const hoverOnButton = ref(false);
const hoverSummaryOnButton = ref(false);
const hoverUploadSummaryOnButton = ref(false);

const emit = defineEmits<{(e:'update:subOrder'): void}>();
const updateSubOrderMutation = useMutation(
  () => subOrdersApi.update(props.subOrder.id, { status: 'delivered' }),
  {
    onSuccess: () => {
      emit('update:subOrder');
    },
  },
);

const generateManifestMutation = useMutation(
  () => {
    if (props.subOrder?.shipmentDetailId) {
      return shipmentDetailsApi.generateAndCloseSummary(props.subOrder?.shipmentDetailId);
    }

    return new Promise(() => ({}));
  },
  {
    onSuccess: () => {
      messageStore.message = 'Estamos generando el manifiesto, por favor espera unos minutos.';
      emit('update:subOrder');
    },
  },
);

const withWarehousePickup = computed(() =>
  ['with_pickup', 'only_summary'].includes(props.maker.warehousePickupCondition));

const summaryExists = computed(() => !!props.subOrder?.summaryDocumentUrl);
</script>

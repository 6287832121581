<template>
  <a
    class="flex fixed bottom-5 z-20 bg-[#25D366] rounded-full md:bottom-8"
    :class="{
      'right-[300px]': !isMobile && menuStore.showCartSidebar,
      'right-64': !isMobile && menuStore.showMenu,
      'left-5': isMobile && menuStore.showCartSidebar,
      'right-5': isMobile && (menuStore.showMenu || !menuStore.showCartSidebar),
      'md:right-8': !isMobile && !(menuStore.showCartSidebar || menuStore.showMenu),
    }"
    :href="`https://wa.me/${contactNumber}?text=¡Hola%20equipo%20Lokal! Necesito ayuda`"
    target="_blank"
    noopener
  >
    <inline-svg-wrapper
      name="whatsapp-logo"
      class="m-5 w-6 h-6 fill-white md:m-5 md:w-7 md:h-7"
    />
  </a>
</template>
<script lang="ts" setup>
import { inject } from 'vue';
import useGeneralMenuStore from 'stores/general-menu-store';
import InlineSvgWrapper from 'components/inline-svg-wrapper.vue';

defineProps<{
  contactNumber: string
}>();

const menuStore = useGeneralMenuStore();
const isMobile = inject('isMobile') as boolean;
</script>

<template>
  <div
    class="flex flex-col w-full text-gray-700"
  >
    <template v-if="!magicLinkSent">
      <form
        class="flex flex-col w-full"
        action="/users/sign_in"
        method="post"
        accept-charset="UTF-8"
        @submit.prevent="submitForm"
      >
        <form-input
          v-model="form.email"
          class="mb-1"
          placeholder="Email"
          :readonly="true"
          type="text"
          name="user[email]"
          label-name="Email"
          :rules="{ required: true, userEmailExists: [form.email] }"
        />
        <div class="flex flex-col mb-2 w-full">
          <div class="flex items-center w-full text-xs md:text-sm">
            <Field
              v-model="form.password"
              class="p-2 w-full rounded-md rounded-r-none border border-r-0 border-lk-light-gray focus:outline-none"
              :class="{ 'border-lk-green': focusOnPassword, 'border-lk-light-gray': !focusOnPassword }"
              :type="showPassword ? 'text' : 'password'"
              name="user[password]"
              label="Contraseña"
              placeholder="Contraseña"
              :rules="{ required: true }"
              @focus="focusOnPassword = true"
              @blur="focusOnPassword = false"
            />
            <button
              type="button"
              class="p-2 rounded-md rounded-l-none border border-l-0"
              :class="{ 'border-lk-green': focusOnPassword, 'border-lk-light-gray': !focusOnPassword }"
              tabindex="-1"
              @click.stop="showPassword = !showPassword"
            >
              <EyeIcon
                v-if="showPassword"
                class="shrink-0 w-4 h-4 md:w-5 md:h-5"
              />
              <EyeSlashIcon
                v-else
                class="shrink-0 w-4 h-4 md:w-5 md:h-5"
              />
            </button>
          </div>
          <ErrorMessage
            class="mt-1 text-xs text-red-500 md:text-sm"
            name="user[password]"
          />
        </div>
        <Field
          v-model="form.rememberMe"
          class="mb-1"
          name="user[remember_me]"
          hidden
        />
        <button class="p-2 mb-1 text-sm text-white bg-lk-green hover:bg-lk-green-deep rounded-md md:text-base">
          {{ form.email ? 'Ingresar' : 'Continuar' }}
        </button>
        <p class="flex justify-center items-center mb-5 text-sm text-center">
          <span class="mr-1">¿No eres tu?</span>
          <button
            class="text-sm text-center hover:text-lk-green underline"
            type="button"
            @click="emit('update:signInEmail', true)"
          >
            Utiliza una cuenta distinta
          </button>
        </p>
        <csrf-token />
      </form>
      <p class="mb-2 text-sm md:text-base">
        ¿Olvidaste tu contraseña?
      </p>
      <lokal-loading
        v-if="isLoading"
        v-model="isLoading"
        size="xs"
        class="mb-2"
      />
      <button
        v-else
        class="p-2 mb-2 text-sm text-lk-green hover:text-white hover:bg-lk-green rounded-md border border-lk-green md:text-base"
        @click="sendMagicLink"
      >
        Enviar enlace de acceso por email
      </button>
      <a
        href="/users/password/new"
        class="p-2 mb-1 text-center text-lk-green hover:text-white hover:bg-lk-green rounded-md border border-lk-green"
      >
        Recuperar contraseña
      </a>
    </template>
    <div
      v-else
      class="flex flex-col items-center text-center"
    >
      <EnvelopeIcon class="w-36 h-36 text-lk-green md:w-44 md:h-44" />
      <DialogTitle class="flex items-center mb-1 text-lg md:text-xl">
        ¡Te envíamos un correo con un link para que puedas ingresar sin contraseña!
      </DialogTitle>
      <p class="mb-5 font-light">
        Si tienes cualquier duda contáctanos.
      </p>
      <button
        class="p-2 w-full text-sm text-white bg-lk-green hover:bg-lk-green-deep rounded-md md:text-base"
        @click="reload"
      >
        Aceptar
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { EnvelopeIcon } from '@heroicons/vue/24/outline';
import { useForm, Field, ErrorMessage } from 'vee-validate';
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/solid';
import { useMutation } from 'vue-query';
import usersApi from 'api/users';
import CsrfToken from './shared/csrf-input.vue';
import FormInput from './shared/form-input.vue';

const props = defineProps<{
  signInEmail: boolean
  email: string
}>();

const form = ref({ email: props.email, password: '', rememberMe: true });
const { submitForm } = useForm();

const emit = defineEmits<{(e: 'update:signInEmail', value: boolean): void,
  (e: 'update:email', value: string): void
}>();

const showPassword = ref(false);
const focusOnPassword = ref(false);
const magicLinkSent = ref(false);
const sendMagicLinkMutation = useMutation(() => usersApi.sendMagicLink(form.value.email), {
  onSuccess: () => {
    magicLinkSent.value = true;
  },
});
const isLoading = computed(() => sendMagicLinkMutation.isLoading.value);

function sendMagicLink() {
  sendMagicLinkMutation.mutate();
}

function reload() {
  if (window.location.pathname.includes('users')) {
    window.location.href = '/';
  } else {
    window.location.reload();
  }
}
</script>

<template>
  <lokal-loading
    v-if="paymentMethodIsLoading"
    v-model="paymentMethodIsLoading"
  />
  <div
    v-else
    class="flex flex-col w-full rounded-t-md"
  >
    <form-radio-inputs
      v-model="payment"
      class="mb-5 text-sm rounded-md md:text-base"
      name="payment"
      label="Método de pago"
      :values="paymentOptionValues"
      :radio-labels="paymentMethodRadioLabels"
      custom-radio-label-classes="p-3 md:p-4 mb-2 text-sm md:text-base flex space-x-2 items-start border-t"
      rules="required"
      @update:model-value="() => emit('update:modelValue', payment)"
    />
    <div
      v-if="payment === 'payNow'"
      class="mb-5 text-sm font-light text-gray-700 md:text-base"
    >
      <div class="p-3 mb-3 rounded-md border md:p-5">
        <button
          class="flex justify-between items-center w-full text-sm text-left md:text-base"
          :class="{ 'mb-3': showTransferInformation }"
          type="button"
          @click="showTransferInformation = !showTransferInformation"
        >
          <p>
            Estos son los datos para pagar con tranferencia electrónica
          </p>
          <ChevronDownIcon
            class="w-4 h-4 md:w-5 md:h-5"
            :class="{ 'rotate-180': showTransferInformation }"
          />
        </button>
        <lokal-transfer-payment-information v-if="showTransferInformation" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue';
import { useQuery } from 'vue-query';
import { ChevronDownIcon } from '@heroicons/vue/24/outline';
import paymentMethodsApi from 'api/payment-methods';
import FormRadioInputs from './shared/form-radio-inputs.vue';
import LokalTransferPaymentInformation from './shared/lokal-transfer-payment-information.vue';

const props = defineProps<{
  modelValue: string
  selectedAddress: Address
  shopId: number
}>();

const emit = defineEmits<{(e: 'update:modelValue', value: string): void}>();
const paymentMethodQuery = useQuery(
  ['payment-methods', props.shopId],
  () => paymentMethodsApi.show(props.shopId),
);
const paymentMethodIsLoading = computed(() => paymentMethodQuery.isLoading.value);
const paymentMethod = computed(() => paymentMethodQuery.data.value?.data.paymentMethod as PaymentMethod);

const paymentOptionValues = computed(() => {
  const options = [] as string[];
  if (paymentMethod.value?.transferNow) options.push('transferNow');
  if (paymentMethod.value?.cardNow) options.push('cardNow');
  if (paymentMethod.value?.payIn30Days) options.push('payIn30Days');
  if (paymentMethod.value?.payIn60Days) options.push('transferLater');

  return options;
});

const showTransferInformation = ref(false);
const payment = ref('');

const payLaterMessage = `
  <p class="flex flex-col">
    <span class="font-semibold">Pagar a 60 días</span>
    <span class="font-light">
      Podrás pagar hasta 60 días después de que recibas los productos. Solo válido para tiendas físicas.
    </span>
  </p>
`;
const payIn30DaysMessage = `
  <p class="flex flex-col">
    <span class="font-semibold">Pagar a 30 días</span>
    <span class="font-light">
      Podrás pagar hasta 30 días después de que recibas los productos. Solo válido para tiendas físicas.
    </span>
  </p>
`;
const payNowMessage = `
  <p class="flex flex-col">
    <span class="font-semibold">Pagar hoy</span>
    <span class="font-light">Deberás pagar la totalidad de la compra para confirmar la orden.</span>
  </p>
`;
const cardNowMessage = `
  <p class="flex flex-col">
    <span class="font-semibold">Pagar hoy con tarjeta</span>
    <span class="font-light">Te envíaremos un link de mercadopago para que puedas realizar el pago.</span>
  </p>
`;

const paymentMethodRadioLabels = computed(() => {
  const labels = [] as string[];
  if (paymentMethod.value?.transferNow) labels.push(payNowMessage);
  if (paymentMethod.value?.cardNow) labels.push(cardNowMessage);
  if (paymentMethod.value?.payIn30Days) labels.push(payIn30DaysMessage);
  if (paymentMethod.value?.payIn60Days) labels.push(payLaterMessage);

  return labels;
});
</script>

<template>
  <div
    v-if="!isLoading && orderedFreeShippings.length > 0"
    class="flex flex-col self-start"
  >
    <div
      v-if="!isLoading && orderedFreeShippings.length > 0"
      class="flex flex-col w-full text-gray-700 bg-lk-light-purple rounded-md divide-y divide-white"
    >
      <button
        class="flex items-center py-2 px-3 sm:py-3 sm:px-4"
        @click="showFreeShippingCards = !showFreeShippingCards"
      >
        <div class="flex justify-center items-center p-1 mr-3 bg-white rounded-full">
          <TruckIcon class="w-5 h-5 text-lk-light-purple" />
        </div>
        <p class="text-sm text-left text-white sm:text-base md:flex-row md:mr-2">
          {{ includeCurrentAddressMessage }}
        </p>
        <ChevronDownIcon
          class="shrink-0 ml-auto w-4 h-4 text-white"
          :class="{ 'rotate-180 transform duration-150 ease-in': showFreeShippingCards,
                    'transform duration-150 ease-out': !showFreeShippingCards }"
        />
      </button>
      <transition
        enter-active-class="transition duration-150"
        enter-from-class="-translate-y-2 opacity-50"
        enter-to-class="translate-y-0 transform opacity-100"
        leave-active-class="transition duration-100"
        leave-from-class="translate-y-0"
        leave-to-class="-translate-y-2 transform opacity-0"
      >
        <div
          v-if="showFreeShippingCards"
          class="flex flex-col w-full"
        >
          <maker-show-free-shipping-card
            v-for="freeShipping in orderedFreeShippings"
            :key="freeShipping.id"
            :free-shipping="freeShipping"
            :start-open="orderedFreeShippings.length === 1"
          />
        </div>
      </transition>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { TruckIcon, ChevronDownIcon } from '@heroicons/vue/24/outline';
import { orderBy } from 'lodash';
import useSessionStore from 'stores/user-store';
import useFreeShippingsQuery from './queries/free-shippings-query';
import MakerShowFreeShippingCard from './maker-show-free-shipping-card.vue';
import useFormatCurrency from './use/format-currency';

interface MakerShowDiscountsProps {
  maker: Maker;
}

const props = defineProps<MakerShowDiscountsProps>();

const sessionStore = useSessionStore();

const showFreeShippingCards = ref(false);
const freeShippingsQueryParams = computed(() => 'q[active_eq]=true');
const {
  freeShippings, freeShippingsQuery,
} = useFreeShippingsQuery(computed(() => props.maker.id), freeShippingsQueryParams);
const isLoading = computed(() => freeShippingsQuery.isLoading.value);
const orderedFreeShippings = computed(() => orderBy(freeShippings.value, ['minimumAmountCents'], ['asc']));

const includesCurrentAddress = computed(() => {
  let isIt = false;
  orderedFreeShippings?.value.forEach(freeShipping => {
    freeShipping.regions.forEach(region => {
      if (region.id === sessionStore.currentRegionId) isIt = true;
    });
    freeShipping.communes.forEach(commune => {
      if (commune.id === sessionStore.currentCommuneId) isIt = true;
    });
  });

  return isIt;
});
const currentFreeShipping = computed(() => {
  let internalFreeShipping: FreeShipping | undefined;
  orderedFreeShippings?.value.forEach(freeShipping => {
    const regionFreeShipping = freeShipping.regions.find((region) => region.id === sessionStore.currentRegionId);
    if (regionFreeShipping) {
      internalFreeShipping = freeShipping;
    }

    const communeFreeShipping = freeShipping.communes.find(
      (commune) => commune.id === sessionStore.currentCommuneId);
    if (communeFreeShipping) internalFreeShipping = freeShipping;
  });

  return internalFreeShipping;
});
const includeCurrentAddressMessage = computed(() => {
  if (includesCurrentAddress.value && currentFreeShipping.value) {
    return `Despacho gratis a tu dirección en compras desde
    ${useFormatCurrency(currentFreeShipping.value?.minimumAmountCents)}`;
  }

  return 'Despacho gratis no disponible. Revisa otras zonas.';
});
</script>

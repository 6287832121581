<template>
  <div class="flex flex-col items-center mb-10 w-full h-full text-gray-700 md:px-5 lg:px-10 xl:max-w-7xl">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-start text-xl text-gray-700 md:text-2xl lg:text-3xl">
        <span>Tutoriales</span>
      </p>
    </div>
    <div class="flex flex-col py-3 px-2 w-full h-full bg-white rounded-md md:py-5 md:px-4 lg:px-10">
      <lokal-loading
        v-if="isLoading"
        :loading="isLoading"
      />
      <div
        v-for="tutorial in tutorials"
        v-else
        :key="tutorial.id"
        class="flex flex-col p-3 mb-5 last:mb-10 w-full rounded-md border border-gray-300 md:p-5"
      >
        <div class="flex flex-col items-center mb-2 md:flex-row md:justify-between">
          <p class="mb-2 text-base md:mb-0 md:text-xl">
            {{ tutorial.title }}
          </p>
          <lokal-button
            v-if="tutorial.documentUrl"
            class="mb-2 text-sm md:mb-0 md:text-base"
            action-name="Descargar Pdf"
            rel="noopener"
            target="_blank"
            :href="tutorial.documentUrl"
            tag="a"
            :size="isMobile ? 'small' : 'normal'"
          />
        </div>
        <iframe
          width="w-full"
          :height="isMobile ? '150' : '350'"
          frameborder="0"
          :src="tutorial.url"
          :title="tutorial.title"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { inject, computed } from 'vue';
import { useQuery } from 'vue-query';
import tutorialsApi from 'api/tutorials';

const isMobile = inject('isMobile');
const { isLoading, data } = useQuery('tutorials', () => tutorialsApi.index());
const tutorials = computed(() => data.value?.data.tutorials as Tutorial[]);
</script>

<template>
  <div class="flex flex-col md:items-center lg:flex-row">
    <p class="flex items-center self-start space-x-1 text-sm font-light md:mr-2 md:text-base">
      <span class="font-normal">#{{ subOrder.id }}</span>
      <span>·</span>
      <span>{{ timeToDate(subOrder.pendingAt) }}</span>
    </p>
    <p class="flex items-center self-start space-x-1 text-sm font-light md:text-base">
      <span v-if="!isMobileOrTablet && (subOrder.paymentStatus === 'paid' || subOrder.subOrderInvoiceUrls?.length)">
        ·
      </span>
      <lokal-tooltip
        v-if="subOrder.paymentStatus === 'paid'"
        position-options="left"
        information-classes="w-64 md:w-80"
        arrow-classes="left-1.5"
        :information="'Este pedido ya fue pagado.'"
      >
        <template #custom-content>
          <CheckBadgeIcon class="shrink-0 w-4 h-4 text-lk-green md:w-5 md:h-5" />
        </template>
      </lokal-tooltip>
      <lokal-tooltip
        v-else-if="subOrder.paymentStatus === 'under_review'"
        position-options="left"
        information-classes="w-64 md:w-80"
        arrow-classes="left-1.5"
        :information="underReviewInformation"
      >
        <template #custom-content>
          <ShieldExclamationIcon class="shrink-0 w-4 h-4 text-red-600 md:w-5 md:h-5" />
        </template>
      </lokal-tooltip>
      <lokal-tooltip
        v-if="subOrder.subOrderInvoiceUrls?.length"
        position-options="left"
        information-classes="w-64 md:w-80"
        arrow-classes="left-1.5"
        :information="'La factura ya está subida a la plataforma.'"
      >
        <template #custom-content>
          <DocumentCheckIcon
            class="shrink-0 w-4 h-4 text-lk-green fill-white stroke-2 md:w-5 md:h-5"
          />
        </template>
      </lokal-tooltip>
      <lokal-tooltip
        v-if="!!subOrder.signedSummaryImageUrl"
        position-options="left"
        information-classes="w-64 md:w-80"
        arrow-classes="left-1.5"
        :information="'El manifiesto firmado ya está subido a la plataforma.'"
      >
        <template #custom-content>
          <DocumentTextIcon class="shrink-0 w-4 h-4 text-lk-green md:w-5 md:h-5" />
        </template>
      </lokal-tooltip>
      <lokal-tooltip
        v-if="hasAnyDiscount"
        position-options="left"
        arrow-classes="left-1.5"
        information-classes="w-64 md:w-80 text-left"
        information="Este pedido tiene algún tipo de descuento (de producto, por volumen o código)."
      >
        <template #custom-content>
          <ReceiptPercentIcon
            class="shrink-0 w-4 h-4 text-lk-green md:w-5 md:h-5"
          />
        </template>
      </lokal-tooltip>
      <lokal-tooltip
        v-if="subOrder.shipmentMethod === 'lokal'"
        position-options="left"
        arrow-classes="left-1.5"
        information-classes="w-64 md:w-80 text-left"
        :information="`El despacho de este pedido es realizado con la etiqueta de Lokal.`"
      >
        <template #custom-content>
          <inline-svg-wrapper
            name="outline-lokal-truck-with-green-k"
            class="shrink-0 w-5 h-5 text-lk-green md:w-6 md:h-6"
          />
        </template>
      </lokal-tooltip>
      <lokal-tooltip
        v-else-if="subOrder.shipmentMethod === 'maker'"
        position-options="left"
        arrow-classes="left-1.5"
        information-classes="w-64 md:w-80 text-left"
        :information="`El despacho de este pedido es realizado por ${subOrder.makerName}.`"
      >
        <template #custom-content>
          <TruckIcon class="shrink-0 w-5 h-5 text-lk-light-purple md:w-6 md:h-6" />
        </template>
      </lokal-tooltip>
      <lokal-tooltip
        v-else-if="subOrder.shipmentMethod === 'shop_pickup'"
        position-options="left"
        arrow-classes="left-1.5"
        information-classes="w-64 md:w-80 text-left"
        :information="`Este pedido será retirado en la bodega de ${subOrder.makerName}.`"
      >
        <template #custom-content>
          <inline-svg-wrapper
            name="warehouse-icon"
            class="shrink-0 w-5 h-5 fill-lk-green md:w-6 md:h-6"
          />
        </template>
      </lokal-tooltip>
      <lokal-tooltip
        v-if="['with_lokal', 'by_maker'].includes(subOrder.freeShippingConditions)"
        position-options="left"
        arrow-classes="left-1.5"
        information-classes="w-64 md:w-80 text-left"
        :information="freeShippingInformation"
      >
        <template #custom-content>
          <span class="p-0.5 text-xs font-semibold text-lk-green bg-white rounded-md border border-lk-green border-dashed md:px-1 md:text-sm">
            {{ subOrder.freeShippingConditions === 'by_maker' ? 'DESPACHO GRATIS' : 'DG C/LOKAL' }}
          </span>
        </template>
      </lokal-tooltip>
      <lokal-tooltip
        v-if="['incidence_in_progress', 'incidence_resolved'].includes(subOrder.incidenceStatus)"
        position-options="left"
        arrow-classes="left-1.5"
        information-classes="w-64 md:w-80 text-left"
        :information="incidenceInformation"
      >
        <template #custom-content>
          <ExclamationCircleIcon
            class="shrink-0 w-4 h-4 md:w-5 md:h-5"
            :class="{ 'text-red-500': subOrder.incidenceStatus === 'incidence_in_progress',
                      'text-lk-green': subOrder.incidenceStatus === 'incidence_resolved' }"
          />
        </template>
      </lokal-tooltip>
    </p>
  </div>
</template>
<script lang="ts" setup>
import {
  CheckBadgeIcon, ExclamationCircleIcon, ShieldExclamationIcon, TruckIcon, DocumentTextIcon,
  DocumentCheckIcon, ReceiptPercentIcon,
} from '@heroicons/vue/24/outline';
import { computed, inject } from 'vue';
import useTimeToDate from './use/time-to-date';
import LokalTooltip from './shared/lokal-tooltip.vue';
import inlineSvgWrapper from './inline-svg-wrapper.vue';
import useDateTools from './use/date-tools';

interface SubOrderListCardProps {
  subOrder: SubOrder,
  maker: Maker
}

const props = withDefaults(defineProps<SubOrderListCardProps>(), {
});

const { timeToDate } = useDateTools();
const isMobileOrTablet = inject('isMobileOrTablet');

const underReviewInformation = computed(() =>
  'Este pedido no se ha podido pagar y ahora está en revisión. Comunícate con nosotros para más información.');

const freeShippingInformation = computed(() => {
  if (props.subOrder.freeShippingConditions === 'with_lokal') {
    return `Despacho gratis con Lokal: este despacho es financiado 50% ${props.subOrder.makerName} y 50% Lokal.`;
  } else if (props.subOrder.freeShippingConditions === 'by_maker') {
    return `El despacho de este pedido es financiado 100% por ${props.subOrder.makerName}.`;
  }

  return '';
});

const incidenceInformation = computed(() => {
  if (props.subOrder.incidenceStatus === 'incidence_in_progress') {
    return 'Este pedido tiene una o más incidencias en progresos. Comunícate con nosotros para más información.';
  } else if (props.subOrder.incidenceStatus === 'incidence_resolved') {
    return 'Este pedido tuvo una o más incidencias que ya están resueltas.';
  }

  return '';
});

const hasAnyDiscount = computed(() =>
  (props.subOrder.totalDiscountAmountCents > 0 || props.subOrder.volumeDiscountAmountCents > 0 ||
  props.subOrder.totalDiscountCodeAmountCents > 0),
);
</script>

<template>
  <div class="flex flex-col items-center mb-10 w-full h-full md:px-5 md:max-w-7xl lg:px-10">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <h2 class="flex flex-col self-end text-xl text-gray-700 md:text-2xl lg:text-3xl">
        <span>Pago de pedidos</span>
      </h2>
    </div>
    <div class="flex flex-col py-3 px-2 w-full h-full text-gray-700 bg-white rounded-md md:py-5 md:px-4 lg:px-10">
      <div class="flex items-center self-start mb-5 w-full text-sm md:text-base">
        <template
          v-for="value, key in statusHash"
          :key="key"
        >
          <button
            class="shrink-0 py-3 border-b-2"
            :class="{ 'border-gray-300' : selectedStatus !== key, 'border-lk-green' : selectedStatus === key }"
            @click="updateSelectedStatus(key)"
          >
            {{ value }}
          </button>
          <div
            class="self-end w-10 last:w-full border-b-2 border-gray-300"
          />
        </template>
      </div>
      <p class="text-base md:text-lg">
        Información importante:
      </p>
      <ul class="px-5 mb-3 text-sm list-disc md:px-8 md:mb-5 md:text-base">
        <li>
          Los pagos se realizan contra factura, por lo que deben estar subidas al sistema para que
          Lokal puede efectuar el pago.
        </li>
        <li>
          La fecha de pago corresponde a {{ maker.maxPaymentDays }} días después de la fecha en que
          <span v-if="maker.paymentConditions === 'from_delivered'">la tienda recibe el pedido.</span>
          <span v-else-if="maker.paymentConditions === 'from_delivey_in_progress'">
            el pedido esta en camino.
          </span>
        </li>
        <li>
          Los pedidos "en revisión" son aquellos donde la factura emitida no cuadra con el monto a pagar
          que tenemos registrado en Lokal.
        </li>
        <li>
          El "Total" considera todos los descuentos aplicados al pedido y la comisión de Lokal.
        </li>
      </ul>
      <a
        v-if="maker.advancePaymentEnabled"
        :href="`https://wa.me/${contactNumber}?text=${advancePaymentMessage}`"
        target="_blank"
        noopener
        class="flex items-center p-1 mb-2 ml-auto text-sm text-lk-green hover:text-white hover:bg-lk-green rounded-md border border-lk-green md:p-2 md:text-base"
      >
        <BoltIcon class="shrink-0 mr-2 w-4 h-4" />
        <span>Adelantar pago de facturas</span>
      </a>
      <div class="mb-1 ml-auto text-xs md:text-sm">
        {{ totalResources }} pedidos
      </div>
      <div class="overflow-x-auto w-full">
        <table class="w-full text-sm border-collapse table-auto md:text-base">
          <thead class="">
            <tr class="bg-gray-50">
              <th class="py-1 px-2 border md:py-2 md:px-4">
                ID
              </th>
              <th class="py-1 px-2 border md:py-2 md:px-4">
                Estado del pedido
              </th>
              <th class="py-1 px-2 border md:py-2 md:px-4">
                Estado de pago
              </th>
              <th class="py-1 px-2 border md:py-2 md:px-4">
                Factura
              </th>
              <th class="py-1 px-2 border md:py-2 md:px-4">
                <!-- Fecha de aprobación -->
                {{ maker.paymentConditions === 'from_delivered' ? 'Fecha de entrega' : 'Fecha de despacho' }}
              </th>
              <th class="py-1 px-2 border md:py-2 md:px-4">
                Fecha de pago
              </th>
              <th class="py-1 px-2 border md:py-2 md:px-4">
                Total
              </th>
            </tr>
          </thead>
          <lokal-loading
            v-if="isSubOrderQueryLoading"
            :loading="isSubOrderQueryLoading"
          />
          <tbody
            v-else
            class=""
          >
            <maker-sub-order-payment-list-card
              v-for="subOrder in subOrders"
              :key="`payment-list-card-${subOrder.id}`"
              :sub-order="subOrder"
              :maker="maker"
              @reload:sub-orders="async () => await subOrdersQuery.refetch.value()"
            />
          </tbody>
        </table>
      </div>
      <pagination-component
        v-if="!isSubOrderQueryLoading && subOrders?.length > 0"
        :current-page="currentPage"
        :total-pages="totalPages"
        @set-new-page="(newPage: number) => currentPage = newPage"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { BoltIcon } from '@heroicons/vue/24/outline';
import useSubOrdersQuery from './queries/sub-orders-query';
import paginationComponent from './shared/pagination-component.vue';
import makerSubOrderPaymentListCard from './maker-sub-order-payment-list-card.vue';

const props = defineProps<{
  maker: Maker;
}>();

const contactNumber = computed(() => process.env.CONTACT_NUMBER);
const advancePaymentMessage = computed(() =>
  `¡Hola equipo Lokal! Quiero adelantar el pago de mis facturas (${props.maker.name}).`);

const DB_STATUSES_HASH = {
  unpaid: 0,
  underReview: 2,
  paid: 1,
};

const STATUSES_HASH = {
  unpaid: 'Por pagar',
  underReview: 'En revisión',
  paid: 'Pagadas',
} as const;
const selectedStatus = ref<keyof typeof STATUSES_HASH>('unpaid');
const statusHash = ref({ unpaid: 'Por pagar', underReview: 'En revisión', paid: 'Pagadas' });

function updateSelectedStatus(status: keyof typeof STATUSES_HASH) {
  selectedStatus.value = status;
}

const queryParams = computed(() => {
  let base = 'q[s][]=pending_at+desc';
  if (selectedStatus.value === 'unpaid') base += `&q[payment_status_eq]=${DB_STATUSES_HASH.unpaid}`;
  else if (selectedStatus.value === 'paid') base += `&q[payment_status_eq]=${DB_STATUSES_HASH.paid}`;
  else if (selectedStatus.value === 'underReview') base += `&q[payment_status_eq]=${DB_STATUSES_HASH.underReview}`;

  return base;
});
const currentPage = ref(1);
const makerId = computed(() => props.maker.id);
const {
  subOrders,
  isSubOrderQueryLoading,
  totalResources,
  totalPages,
  subOrdersQuery,
} = useSubOrdersQuery(makerId, currentPage, queryParams);

watch(selectedStatus, () => {
  currentPage.value = 1;
});
</script>

<template>
  <div class="flex flex-col items-center w-full">
    <template
      v-for="message, index in messages"
      :key="message"
    >
      <lokal-banner-message-dialog
        v-if="message[1]"
        v-model="showBanners[index]"
        :show-dialog="message[0].includes('dialog')"
        :color-key="(message[0] as ColorKeyType)"
        :message="message[1]"
        title="Información importante"
      />
    </template>
    <product-list-trends />
    <div class="flex w-full max-w-7xl">
      <div
        v-if="menuStore.showProductFilterSidebar && !isMobile"
        class="overflow-y-hidden fixed inset-0 z-20 bg-gray-800/50"
        aria-hidden="true"
        @click="menuStore.showProductFilterSidebar = false"
      />
      <product-list-aside-filters
        v-if="menuStore.showProductFilterSidebar || !isMobile"
        :categories="categories"
        :cross-enabled="isMobile"
        :sub-categories="subCategories"
        class="overflow-y-auto fixed left-0 z-20 w-3/5 h-screen text-sm text-gray-700 bg-gray-50 sm:static sm:left-auto sm:z-0 md:overflow-hidden md:w-80 md:h-auto md:text-base xl:bg-white"
        :style="{ top: menuStore.headerHeight + 'px' }"
        :class="{ 'border-r border-gray-300': isMobile }"
      />
      <div class="flex flex-col py-6 px-3 w-full md:p-10 xl:px-0">
        <button
          v-if="regionSubsidizedMinimum"
          class="flex flex-col items-center p-2 mb-3 text-sm text-white bg-lk-light-green rounded-md md:flex-row md:py-5 md:px-3 md:text-base"
          @click="showShippingDiscountsDialog = !showShippingDiscountsDialog"
        >
          <InformationCircleIcon class="shrink-0 mb-2 w-5 h-5 md:mr-2 md:mb-0 md:w-6 md:h-6" />
          <p class="flex flex-col text-left">
            <span>¡Aprovecha 50% de descuento en los despachos cuando compras 2 o más marcas!</span>
            <span class="font-light">
              Válido para compras sobre {{ useFormatCurrency(regionSubsidizedMinimum) }} de cada una de las
              marcas a tu dirección de despacho actual.
            </span>
            <span class="font-light">
              Revisa los detalles <span class="underline">acá.</span> Válido hasta el 31/08/2024. Descuento sujeto a evaluación
              para zonas extremas.
            </span>
          </p>
        </button>
        <product-list-display
          :categories="categories"
          :sub-categories="subCategories"
          :makers="makers"
          :total-product-count="totalProductCount"
        />
      </div>
    </div>
    <shipping-discounts-dialog
      v-if="showShippingDiscountsDialog"
      v-model="showShippingDiscountsDialog"
    />
  </div>
</template>
<script setup lang="ts">
import { ref, computed, inject } from 'vue';
import { InformationCircleIcon } from '@heroicons/vue/24/outline';
import useGeneralMenuStore from 'stores/general-menu-store';
import useSessionStore from 'stores/user-store';
import ProductListDisplay from './product-list-display.vue';
import type { ColorKeyType } from './shared/lokal-banner.vue';
import productListAsideFilters from './product-list-aside-filters.vue';
import ProductListTrends from './product-list-trends.vue';
import useFormatCurrency from './use/format-currency';
import ShippingDiscountsDialog from './shared/shipping-discounts-dialog.vue';
import useRegionsQuery from './queries/regions-query';

interface ProductListProps {
  categories: Category[],
  subCategories: SubCategory[],
  makers: Maker[],
  totalProductCount?: number,
  messages?: string[][]
}

const props = withDefaults(defineProps<ProductListProps>(), {
  messages: () => [['', '']],
  totalProductCount: 0,
});

const sessionStore = useSessionStore();
const currentRegionId = computed(() => sessionStore.currentRegionId);
const regionsEnabled = computed(() => !!sessionStore.user?.shopId && !sessionStore.currentAddress?.id);
const { regions } = useRegionsQuery(regionsEnabled);
const regionSubsidizedMinimum = computed(() => {
  if (sessionStore.currentAddress?.id) return sessionStore.currentAddress?.regionSubsidizedMinimum as number;

  return regions.value?.find((region) => region.id === currentRegionId.value)?.subsidizedMinimum as number;
});
const showShippingDiscountsDialog = ref(false);

const menuStore = useGeneralMenuStore();
const isMobile = inject('isMobile') as boolean;
const showBanners = ref({} as { [string: number]: boolean });
props.messages.forEach((_, index) => {
  showBanners.value[index] = true;
});
</script>

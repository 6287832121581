<template>
  <div class="flex flex-col items-center mb-10 w-full h-full md:px-5 md:max-w-7xl lg:px-10">
    <div class="flex items-center px-2 mb-3 w-full md:px-0">
      <p class="flex flex-col self-end text-xl text-gray-700 md:text-2xl lg:text-3xl">
        Direcciones
      </p>
      <button
        class="flex items-center p-2 ml-auto text-sm text-lk-green hover:text-white bg-white hover:bg-lk-green rounded-md border border-lk-green md:p-3 md:text-base"
        @click="showNewAddressDialog = !showNewAddressDialog"
      >
        <PlusIcon class="shrink-0 mr-2 w-5 h-5" />
        <span>Agregar descuento</span>
      </button>
    </div>
    <div class="flex flex-col py-3 px-2 w-full bg-white rounded-md md:py-5 md:px-4 lg:px-10">
      <lokal-loading
        v-if="isLoading"
        :loading="isLoading"
      />
      <div
        v-for="address in addresses"
        v-else
        :key="address.id"
        class="flex flex-col p-2 mb-2 rounded-md border border-lk-light-gray md:p-3"
      >
        <div class="flex items-center">
          <inline-svg-wrapper
            name="warehouse-icon"
            class="shrink-0 mr-1 w-10 h-10 md:w-12 md:h-12"
          />
          <p class="text-base capitalize md:text-lg">
            {{ address.nameReference.toLocaleLowerCase() }}
          </p>
        </div>
        <p class="text-sm md:text-base">
          <span>{{ address.address }}</span>
        </p>
        <p class="text-sm md:text-base">
          <span>{{ address.communeName }}</span>
          <span>, {{ address.regionName }}</span>
        </p>
        <button
          class="flex items-center"
          @click="updateDefaultAddress(address)"
        >
          <div
            class="mr-2 w-4 h-4 rounded-md border border-lk-green"
            :class="{ 'bg-lk-green': shop.defaultAddressId === address.id }"
          />
          <p>
            Dirección por defecto
          </p>
        </button>
      </div>
    </div>
    <address-new-dialog
      v-if="showNewAddressDialog"
      v-model="showNewAddressDialog"
      :addressable-id="shopId"
      addressable-type="Shop"
      :initial-region-id="initialRegionId"
      :initial-commune-id="initialCommuneId"
      @update:model-value="showNewAddressDialog = false"
    />
  </div>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useMutation, useQuery } from 'vue-query';
import { PlusIcon } from '@heroicons/vue/24/outline';
import shopsApi from 'api/shops';
import useMessageStore from 'stores/message-store';
import useAddressesQuery from './queries/addresses-query';
import AddressNewDialog from './address-new-dialog.vue';
import inlineSvgWrapper from './inline-svg-wrapper.vue';

interface ShopAddressesProps {
  shopId: number
}

const props = withDefaults(defineProps<ShopAddressesProps>(), {
});

const messageStore = useMessageStore();

const shopQuery = useQuery(['shops', props.shopId], () => shopsApi.get(props.shopId));
const isShopQueryLoading = computed(() => shopQuery.isLoading.value);
const shop = computed(() => shopQuery.data.value?.data.shop as Shop);
const showNewAddressDialog = ref(false);

const computedShopId = computed(() => props.shopId);
const computedShopType = computed(() => 'Shop');
const { addresses, addressesQuery } = useAddressesQuery(computedShopId, computedShopType);
const addressesQueryIsLoading = computed(() => addressesQuery.isLoading.value);

const updateMakerDefaultAddressMutation = useMutation(
  (address: Address) => shopsApi.update(props.shopId, { defaultAddressId: address.id }), {
    onSuccess: async (data) => {
      const addressId = data.data.shop.defaultAddressId;
      const address = addresses.value.find((internalAddress) => internalAddress.id === addressId);
      await addressesQuery.refetch.value();
      await shopQuery.refetch.value();
      messageStore.kind = 'success';
      messageStore.message = `Dirección por defecto actualizada correctamente a ${address?.address}`;
    },
  },
);
const mutationIsLoading = computed(() => updateMakerDefaultAddressMutation.isLoading.value);
const isLoading = computed(() => mutationIsLoading.value || addressesQueryIsLoading.value || isShopQueryLoading.value);

function updateDefaultAddress(address: Address) {
  const addressId = address.id;
  if (shop.value?.defaultAddressId === addressId) {
    messageStore.kind = 'error';
    messageStore.message = 'Esta dirección ya es la dirección por defecto';
  } else {
    updateMakerDefaultAddressMutation.mutate(address);
  }
}

const initialRegionId = computed(() => {
  if (addresses.value?.length && addresses.value?.length > 0) return undefined;

  return shop.value?.referenceRegionId;
});
const initialCommuneId = computed(() => {
  if (addresses.value?.length && addresses.value?.length > 0) return undefined;

  return shop.value?.referenceCommuneId;
});
</script>

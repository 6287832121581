<template>
  <div class="mb-5">
    <div
      class="flex justify-between items-center mb-1 w-full"
    >
      <button
        class="flex items-center space-x-2"
        @click="isOpen = !isOpen"
      >
        <h2 class="text-lg md:text-xl">
          {{ subOrder.makerName }}
        </h2>
        <ChevronDownIcon
          class="w-4 h-full"
          :class="{ 'rotate-180 transform duration-150 ease-linear': isOpen,
                    'duration-150 ease-linear transform': !isOpen}"
        />
      </button>
      <LokalDropdown
        :labels="['Ver Seguimiento', 'Guía de despacho', 'Material de apoyo']"
        :hrefs="[subOrder.trackingUrl, subOrder.shipmentInvoiceUrl, subOrder.supportMaterialLink]"
        main-label="Ver más"
      />
    </div>
    <p class="mb-2 text-sm text-left">
      <template v-if="subOrder.shipmentDetailId">
        <span v-if="subOrder.shipmentDetailId && subOrder.deadlineAt">
          Fecha estimada de entrega: {{ formattedDeadlineAt }}.
          <br v-if="isMobile">
        </span>
        <a
          v-if="subOrder.trackingUrl"
          :href="subOrder.trackingUrl"
          class="underline"
          target="blank"
          rel="nofollow"
        >
          Ver seguimiento.
        </a>
      </template>
      <template v-else-if="subOrder.makerShipmentDetailId">
        <span>
          Este despacho es realizado por la marca.
        </span>
        <span v-if="subOrder.initialDeliveryAt && subOrder.deadlineAt">
          Entre {{ useTimeToDate(subOrder.initialDeliveryAt) }} y
          {{ formattedDeadlineAt }}.
        </span>
        <a
          v-else-if="subOrder.trackingUrl"
          :href="subOrder.trackingUrl"
          class="hover:underline"
          target="blank"
          rel="nofollow"
        >
          Ver seguimiento
        </a>
      </template>
    </p>
    <div
      :class="{ 'border-gray-300': !isOpen, 'border-gray-700': isOpen }"
      class="col-span-6 mb-3 w-full border-b-2 md:col-span-7"
    />
    <div
      v-if="isOpen"
      class="mb-5 text-sm text-gray-700 md:grid md:grid-cols-7 md:gap-4 md:place-items-center md:text-base"
    >
      <template v-if="!isMobile">
        <p class="text-center">
          Imagen referencial
        </p>
        <p class=" place-self-center md:col-span-2">
          Nombre producto
        </p>
        <p class="text-center">
          Precio público <br> (c/IVA)
        </p>
        <p class="text-center">
          Precio mayorista <br> neto
        </p>
        <p class="text-center">
          Cantidad
        </p>
        <p class="text-center">
          Suma
        </p>
        <div class="col-span-6 w-full border-b border-gray-700 md:col-span-7" />
      </template>
      <template
        v-for="subOrderProduct in subOrderProducts"
        :key="subOrderProduct.id"
      >
        <OrderShowSubOrderProductCard
          :sub-order-product="subOrderProduct"
        />
      </template>
      <div class="flex flex-col col-span-5 ml-auto md:col-span-7">
        <div
          v-if="['free_shipping_with_lokal', 'free_shipping_by_maker'].includes(subOrder.shippingConditions)"
          class="flex justify-end w-full"
        >
          <p class="p-1 text-sm text-white bg-lk-light-purple rounded-md">
            Despacho gratis
          </p>
        </div>
        <p
          v-if="subOrder.volumeDiscountAmountCents > 0"
          class="flex space-x-3 md:justify-end"
        >
          <span>DESCT X VOLUMEN ({{ subOrder.volumeDiscountValue }}%):</span>
          <span>{{ useFormatCurrency(subOrder.volumeDiscountAmountCents) }}</span>
        </p>
        <p
          v-if="subOrder.totalDiscountCodeAmountCents > 0"
          class="flex space-x-3 md:justify-end"
        >
          <span>CÓDIGO DESCT:</span>
          <span>{{ useFormatCurrency(subOrder.totalDiscountCodeAmountCents) }}</span>
        </p>
        <p
          class="flex space-x-3 md:justify-end"
        >
          <span>SUB TOTAL:</span>
          <span
            v-if="discountActive"
            :class="{ 'line-through': discountActive }"
          >
            {{ useFormatCurrency(subOrder.totalWholesaleAmountCents) }}
          </span>
          <span>
            {{ useFormatCurrency(totalProductsPriceCents) }}
          </span>
        </p>
        <p
          class="flex space-x-3 md:justify-end"
        >
          <span>DESPACHO:</span>
          <span v-if="!!subOrder.shopPickupAddressId">
            $0
          </span>
          <template v-else>
            <span
              v-if="differentShippingPrices"
              :class="{ 'line-through': differentShippingPrices }"
            >
              {{ useFormatCurrency(subOrder.originalShippingCostAmountCents) }}
            </span>
            <span>
              {{ useFormatCurrency(subOrder.shippingCostAmountCents) }}
            </span>
          </template>
        </p>
        <p
          v-if="!!subOrder.shopPickupAddressId"
          class="flex space-x-3 md:justify-end"
        >
          <span>Retiro en bodega:</span>
          <span>
            {{ shopPickupAddress?.address }}, {{ shopPickupAddress?.communeName }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { inject, ref, computed } from 'vue';
import { ChevronDownIcon } from '@heroicons/vue/24/outline';
import useFormatCurrency from './use/format-currency';
import OrderShowSubOrderProductCard from './order-show-sub-order-product-card.vue';
import LokalDropdown from './shared/lokal-dropdown.vue';
import useTimeToDate from './use/time-to-date';
import useAddressQuery from './queries/address-query';

const isMobile = inject('isMobile');

interface OrderShowSubOrderProps {
  subOrder: SubOrder,
  subOrderProducts: SubOrderProduct[],
  isFirst: boolean
}

const props = withDefaults(defineProps<OrderShowSubOrderProps>(), {
  isFirst: false,
});

const totalProductsPriceCents = computed(() =>
  props.subOrder.totalWholesaleAmountCents - props.subOrder.totalDiscountAmountCents -
  props.subOrder.volumeDiscountAmountCents - props.subOrder.totalDiscountCodeAmountCents);
const discountActive = computed(() => props.subOrder.totalDiscountAmountCents > 0 ||
  props.subOrder.volumeDiscountAmountCents > 0);

const isOpen = ref(false || props.isFirst);

const formattedDeadlineAt = computed(() => {
  if (props.subOrder.deadlineAt) {
    return new Date(props.subOrder.deadlineAt.replace(/-/g, '/')).toLocaleDateString();
  }

  return '';
});

const differentShippingPrices = computed(() =>
  props.subOrder.shippingCostAmountCents !== props.subOrder.originalShippingCostAmountCents);

const { address: shopPickupAddress } = useAddressQuery(computed(() => props.subOrder.shopPickupAddressId));
</script>

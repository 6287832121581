<template>
  <div class="flex overflow-y-auto flex-col p-4 w-full h-full text-sm text-gray-700 md:text-base">
    <slot name="cross" />
    <template v-if="showIcon">
      <inline-svg-wrapper
        v-if="(order.paymentMethod === 'transfer_now' || order.paymentMethod === 'card_now')"
        name="chat"
        class="shrink-0 self-center mr-1 mb-2 w-14 h-14 text-lk-green md:w-20 md:h-20"
      />
      <inline-svg-wrapper
        v-else
        name="rocket"
        class="shrink-0 self-center mr-1 mb-2 w-14 h-14 text-lk-green md:w-20 md:h-20"
      />
    </template>
    <p
      class="mb-2 text-lg font-semibold md:mb-3 md:text-xl"
      :class="{ 'text-left': !showIcon, 'text-center': showIcon }"
    >
      {{ messageTitle }}
    </p>
    <div v-if="order.paymentMethod === 'transfer_now'">
      <p class="mb-2 font-light">
        ¡Escogiste la opción de <b>pagar ahora</b>! Para poder avanzar con la orden debes depotizar
        a la siguiente cuenta bancaria.
      </p>
      <lokal-transfer-payment-information :with-titles="false" />
    </div>
    <template v-else-if="order.paymentMethod === 'card_now'">
      <p class="mb-2 text-base font-light">
        ¡Escogiste la opción de <span class="font-normal">pagar ahora con tarjeta</span>!
        Te envíaremos un link de mercadopago por whatsapp para que puedas pagar tu orden.
      </p>
      <p class="mb-4 text-sm font-light md:text-base">
        *Todos los pedidos recibidos los días sábados, domingos y feriados serán contactados el siguiente día hábil.
      </p>
    </template>
    <template v-else>
      <lokal-loading
        v-if="isBalanceAccountLoading"
        :loading="isBalanceAccountLoading"
        class="self-center"
      />
      <template
        v-else-if="balanceAccount.maximumCreditsApprovedDate"
      >
        <template v-if="balanceAccount.creditsLeft < 0">
          <p class="mb-2 text-base font-light">
            Tu nueva orden #{{ order.id }} es por un monto ({{ useFormatCurrency(totalAmountWithTaxes) }})
            superior a los créditos disponibles en tu cuenta ({{ useFormatCurrency(creditsBeforeOrder) }}).
          </p>
          <p class="mb-2 text-base font-light">
            Para poder avanzar con la orden
            <b>debes depositar ({{ useFormatCurrency(-balanceAccount.creditsLeft) }})</b>
            a la siguiente cuenta bancaria.
          </p>
          <lokal-transfer-payment-information :with-titles="false" />
        </template>
        <template v-else>
          <p class="mb-2 text-base font-light">
            Estamos confirmando tu nueva orden #{{ order.id }} con la/s marca/s que compraste.
          </p>
          <p class="mb-2 text-base font-light">
            Podrás pagar tu orden a {{ order.paymentMethod === 'transfer_later' ? 60 : 30 }}
            días después de de recibir tus productos.
          </p>
        </template>
      </template>
      <template v-else>
        <p class="mb-2 text-base font-light">
          Te <span class="font-normal">llamaremos</span>* en unos mintuos para
          terminar de aprobar tu orden.
        </p>
        <p class="mb-2 text-base font-light">
          Si en 30 minutos no te hemos contactado nos puedes escribir por whatsapp o
          llamarnos al teléfono
          <a
            class="underline"
            :href="`https://wa.me/${phoneNumber}`"
            target="_blank"
            noopener
          >
            +{{ phoneNumber }}
          </a>.
        </p>
        <p class="mb-4 text-sm font-light md:text-base">
          *Todos los pedidos recibidos los días sábados, domingos y feriados serán contactados el siguiente día hábil.
        </p>
      </template>
    </template>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import LokalTransferPaymentInformation from './shared/lokal-transfer-payment-information.vue';
import useBalanceAccountQuery from './queries/balance-account-query';
import useFormatCurrency from './use/format-currency';
import inlineSvgWrapper from './inline-svg-wrapper.vue';

interface OrderShowInitialMessageDialogProps {
  order: Order,
  totalAmountWithTaxes: number
  showIcon?: boolean
}

const props = withDefaults(defineProps<OrderShowInitialMessageDialogProps>(), {
  showIcon: true,
});

const phoneNumber = computed(() => process.env.CONTACT_NUMBER);

const {
  balanceAccount, isLoading: isBalanceAccountLoading,
} = useBalanceAccountQuery(computed(() => props.order.shopId));

const creditsBeforeOrder = computed(() => {
  if (balanceAccount.value.maximumCreditsApprovedDate) {
    return balanceAccount.value.creditsLeft + props.totalAmountWithTaxes;
  }

  return 0;
});

const messageTitle = computed(() => {
  if (props.order.paymentMethod === 'transfer_now' || props.order.paymentMethod === 'card_now') {
    return 'Realiza tu pago para avanzar';
  }

  return '¡Felicitaciones por tu nueva orden!';
});
</script>
